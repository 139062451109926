import React, { useState, useEffect } from "react"
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"

import { Row, Col, Card, CardBody, Modal, Spinner, Button } from "reactstrap"

import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { get, del, post } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { ValidateUser } from "../../helpers/validate_user";
import { toast } from "react-toastify";
import { HeaderFormatter } from "../../helpers/methods";
import { AvForm, AvField } from "availity-reactstrap-validation"
import FileSaver from "file-saver";
import Select from "react-select";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import moment from "moment-timezone";

const optionGroup = [
  {
    label: "Search by",
    options: [
      { label: "Tracking Id", value: "to" },
      { label: "Caller Id", value: "from_field" },
      { label: "Status", value: "status" },
      { label: "Duration", value: "duration" },
      { label: "Business Name", value: "campaign__contractor__bus_name" },
      { label: "Contractor Name", value: "campaign__contractor__contact_name" },
      { label: "Date", value: "date" },
    ],
  },
];

const CallArchive = (props) => {

  const [callArchive, setCallArchive] = useState([]);
  const [totalSize, settotalSize] = useState(0);
  const [page, setpage] = useState(1)
  const [sizePerPage, setsizePerPage] = useState(20)
  const [selected_id, setSelected_id] = useState(null)
  const [loaddata, setLoadData] = useState(false)
  const [success_confirm, setSuccess_confirm] = useState(false)
  const [alert_confirm, setAlert_confirm] = useState(false)
  const [dynamic_title, setDynamic_title] = useState("")
  const [dynamic_description, setDynamic_description] = useState("")
  const [modal_center, setmodal_center] = useState(false)
  const [audio, setAudio] = useState("")
  const [filter, setFilter] = useState("")
  const [search_by, setSearchby] = useState({ label: "Select Search Field", value: null });
  const [fromDate, setFromDate] = useState(moment(new Date()).subtract(7, 'd').tz("America/Chicago").format("YYYY-MM-DD"))
  const [toDate, setToDate] = useState(moment(new Date()).tz("America/Chicago").format("YYYY-MM-DD"))

  ValidateUser(props)

  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true
    },
    {
      dataField: "start_time",
      text: "Date",
      headerFormatter: HeaderFormatter,
      formatter: (cellContent, row) => (
        moment(cellContent.replace("+0000", "")).tz("America/Chicago").format('MM-DD-YYYY hh:mm A')

      ),
    },
    {
      dataField: "to",
      text: "Tracking # Called",
      headerFormatter: HeaderFormatter,
      formatter: (cellContent) => (
        cellContent.replace("+1", "")
      ),
    },
    {
      dataField: "from_field",
      text: "Caller ID",
      headerFormatter: HeaderFormatter,
      formatter: (cellContent) => (
        cellContent.replace("+1", "")
      ),
    },
    {
      dataField: "contractor",
      text: "Contractor",
      headerFormatter: HeaderFormatter,
      formatter: (cellContent) => (
        <Link to="#" className="me-3 text-dark">{(cellContent ? cellContent.split("(")[0] : "").substring(0, 16)}</Link>
      ),
      title: (cell) => cell ? cell.split("(")[0] : ""
    },
    {
      dataField: "status",
      text: "Status",
      headerFormatter: HeaderFormatter,
    },
    {
      dataField: "duration",
      text: "Duration",
      headerFormatter: HeaderFormatter,
    },
    {
      dataField: "recording",
      isDummyField: true,
      text: "Recording",
      headerFormatter: HeaderFormatter,
      formatter: (cellContent, row) => (
        <React.Fragment>
          <Link to={'#'} onClick={() => getRecording(row.sid, row.bucket, 0)} className="me-3 text-primary"><i className="fas fa-play-circle font-size-18"></i></Link>
          <Link to={'#'} onClick={() => getRecording(row.sid, row.bucket, 1)} className="me-3 text-primary"><i className="fas fa-save font-size-18"></i></Link>
        </React.Fragment>
      ),
    },
    {
      dataField: "reviewed",
      text: "Reviewed",
      headerFormatter: HeaderFormatter,
      formatter: (cellContent, row) => (
        <input type='checkbox' defaultChecked={cellContent} onChange={(e) => changeReviewed(e.target.checked, row.sid)}></input>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      headerFormatter: HeaderFormatter,
      formatter: (cellContent, row) => (
        <React.Fragment>

          <Link to="#" onClick={() => { setAlert_confirm(true); setSelected_id(row.id) }} className="text-danger"><i className="mdi mdi-trash-can font-size-18"></i></Link>
        </React.Fragment>
      ),
    },
  ]

  useEffect(() => {
    loadData(`${BASE_URL}/api/tgcl/callarchive/?page_size=${sizePerPage}`, sizePerPage, page)

  }, []);

  function ClearFilter() {

    setFilter("")
    setSearchby({ label: "Select Search Field", value: null });
    setFromDate(moment(new Date()).subtract(7, 'd').tz("America/Chicago").format("YYYY-MM-DD"))
    setToDate(moment(new Date()).tz("America/Chicago").format("YYYY-MM-DD"))

    loadData(`${BASE_URL}/api/tgcl/callarchive/?page_size=${sizePerPage}`, sizePerPage, page)

  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function loadData(url, sizePerPage, page) {
    console.log(url, sizePerPage, page, "-------------");
  
    let data = get(url, { headers: { 'Content-Type': 'application/json' } });
    
    data.then(response => {
      let results = response.data.results;
      console.log(response, "response");
  
      // Create an array to hold all promises for findData calls
      let promises = results.map(result => {
        if (result.campaign === null) {
          let phoneNumber = result.from_field.replace(/\D+1/g, "");
          let updatedPhoneNumber = phoneNumber.slice(0, 3) + "-" + phoneNumber.slice(3, 6) + "-" + phoneNumber.slice(6, 15);
          let findUrl = `${BASE_URL}/api/tgcl/dashboard/tracking-numbers/?search=${updatedPhoneNumber}`;
  
          console.log(findUrl, updatedPhoneNumber);
  
          // Return a new promise that fetches contractor data
          return get(findUrl, { headers: { 'Content-Type': 'application/json' } }).then(findResponse => {
            if (findResponse.data.results.length !== 0) {
              // Update the result with contractor details
              return {
                ...result,
                contractor: "*"+findResponse.data.results[0].contractor_bus_name+"*",
                campaign: {
                  contractor: findResponse.data.results[0].contractor_bus_name+"*",
                  pg_title: findResponse.data.results[0].contractor_bus_name+"*"
                }
              };
            }
            return result;
          }).catch(err => {
            console.error('Error fetching contractor:', err);
            return result; // Return original result in case of error
          });
        }
        return Promise.resolve(result); // If no campaign is null, return the original result
      });
  
      // Wait for all promises to resolve before setting the state
      Promise.all(promises).then(updatedResults => {
        console.log(updatedResults, "updatedResults");
        setCallArchive(updatedResults);
        settotalSize(response.data.count);
        setLoadData(true);
        setpage(page);
        setsizePerPage(sizePerPage);
      });
  
    }).catch(err => console.error('Error fetching data:', err));  // Error handling
  }

  function changeReviewed(value, sid) {
    // 
    let data = { "sid": sid, }
    if (value) {
      data["status"] = 1
    } else {
      data["status"] = 0
    }
    post(`${BASE_URL}/api/tgcl/dashboard/update-reviewed/`, data, { headers: { 'Content-Type': 'application/json', } })
      .then(response => {
        if (response.status < 300) {
          let url = `${BASE_URL}/api/tgcl/callarchive/?page_size=${sizePerPage}&page=${page}`
          // url = SearchText && SearchText !== "" ? url+`&search=${SearchText}`: url
          loadData(url, sizePerPage, page)
        }
      })
  }

  function getRecording(sid, bucket, download) {
    get(`${BASE_URL}/api/tgcl/dashboard/get-recording/?sid=${sid}`, { headers: { 'Content-Type': 'application/json', } })
      .then(response => {
        console.log(response, '---------------------------resp')
        if (response.status < 300) {
          let media_url = response.data.recordings[0]
          if (media_url) {

            media_url = media_url.sid
            let url = `https://${bucket}.s3.amazonaws.com/${media_url}.mp3`
            if (download === 0) {
              setAudio(url)
              tog_center()
            }
            else {
              FileSaver.saveAs(url);

            }

          } else {
            toast.error("Audio Not Found")
          }
        }
      })

  }

  function handleIDeleteCallClicks(id) {

    del(`${BASE_URL}/api/tgcl/callarchive/${id}/`,
      { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false }
    )
      .then(res => {
        if (res.status >= 400) {
          console.log("error")
        }
        else {
          let new_page = page
          if (callArchive.length < 2) {
            new_page = new_page > 1 ? new_page - 1 : 1
          }
          let url = `${BASE_URL}/api/tgcl/callarchive/?page_size=${sizePerPage}&page=${new_page}`
          url = filter !== "" ? url + `${filter}` : url
          loadData(url, sizePerPage, new_page)
          setSuccess_confirm(true)
          setAlert_confirm(false)
          setDynamic_title('Deleted!')
          setDynamic_description("Call Has been deleted.")
          setSelected_id(null)
        }
      }
      )
  }

  function NoDataIndication() {
    if (loaddata && callArchive.length === 0) {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <h4 className="my-3">Table is Empty</h4>
        </div>
      )

    } else {
      return (

        <div className="d-flex align-items-center justify-content-center">
          <Spinner className="my-3" color="dark" />
        </div>
      )

    }
  }

  function FilterData(e, values) {
    let filter_data = `&from_date=${fromDate}&to_date=${toDate}`
    if (!search_by.value) {
      toast.error("Select Search Field")
    } else {
      if (search_by.value === "to" || search_by.value === "from_field") {
        filter_data = filter_data + `&${search_by.value}=${values.search}`
      } else if (search_by.value === "date") {
        filter_data = filter_data + ""
      } else {
        filter_data = filter_data + `&${search_by.value}=${values.search}`
      }
      let url = `${BASE_URL}/api/tgcl/callarchive/?page_size=${sizePerPage}&page=${1}${filter_data}`
      loadData(url, sizePerPage, 1)
      setFilter(filter_data)
    }
  }



  function handleTableChange(type, { page, sizePerPage }) {
    setTimeout(() => {
      setLoadData(false)
      setCallArchive([])
      let new_page = page
      let url = `${BASE_URL}/api/tgcl/callarchive/?page_size=${sizePerPage}&&page=${new_page}`
      if (filter !== "") {
        url = `${BASE_URL}/api/tgcl/callarchive/?page_size=${sizePerPage}&page=${new_page}${filter}`
      } window.scrollTo(0, 0)
      loadData(url, sizePerPage, new_page)
    }, 300);
  }

  const defaultSorted = [{
    dataField: 'start_time',
    order: 'desc'
  }];

  //pagination customization
  const pageOptions = {
    page: page,
    sizePerPage: sizePerPage,
    totalSize: totalSize, // replace later with size(Order),
    custom: true,
    sizePerPageList: [{
      text: '20', value: 20
    }, {
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }, {
      text: '200', value: 200
    }]
  }

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem='Call Tracking Archive' link="/dashboard" />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  data={callArchive}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={callArchive}
                      columns={columns}
                      remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                      bootstrap5
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>

                          <AvForm className="needs-validation" onValidSubmit={FilterData}>
                            <Row className="mb-2">
                              <Col sm="3">
                                <div className="text-sm-start mb-2">
                                  <Select
                                    value={search_by}
                                    onChange={(e) => {
                                      setSearchby(e)
                                    }}
                                    options={optionGroup}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>


                              </Col>
                              {search_by.value ?
                                <Col sm="9">
                                  <Row>
                                    <Col sm="8">

                                      {search_by.value !== null ?
                                        <Row>
                                          {search_by.value !== "date" ?
                                            <Col sm="4">
                                              <div className="text-sm-end mb-2">
                                                <AvField
                                                  name="search"
                                                  placeholder={search_by.label}
                                                  type="text"
                                                  errorMessage=""
                                                  className="form-control"
                                                  validate={{ required: { value: true } }}
                                                />
                                              </div>

                                            </Col> : null}
                                          <Col sm="4">
                                            <div className="text-sm-end mb-2">
                                              <AvField
                                                name="from_date"
                                                placeholder="Received From"
                                                type="date"
                                                errorMessage="From Date"
                                                className="form-control"
                                                value={fromDate}
                                                onChange={(e) => setFromDate(e.target.value)}
                                                id="from_date"
                                              />
                                            </div>

                                          </Col>
                                          <Col sm="4">
                                            <div className="text-sm-end mb-2">
                                              <AvField
                                                name="to_date"
                                                placeholder="Category"
                                                type="date"
                                                errorMessage="To Date"
                                                className="form-control"
                                                value={toDate}
                                                onChange={(e) => setToDate(e.target.value)}
                                                id="to_date"
                                              />
                                            </div>
                                          </Col>

                                        </Row>

                                        : null}

                                    </Col>
                                    <Col sm="4">


                                      <Button type="submit" color="success" className="btn-rounded me-2" >
                                        Search
                                      </Button>
                                      {" "}
                                      <Button type="button" color="primary" onClick={() => ClearFilter()} className="btn-rounded" >
                                        Clear
                                      </Button>

                                    </Col>
                                  </Row>
                                </Col>
                                : null}

                            </Row>
                          </AvForm>
                          <div className="table-responsive">

                            <BootstrapTable
                              remote
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              responsive
                              bordered={false}
                              striped={true}
                              hover
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              onTableChange={handleTableChange}
                              noDataIndication={() => NoDataIndication()}
                            />

                          </div>
                          <Row>
                            <Col sm={6}>
                              <div className="mb-3">
                                <SizePerPageDropdownStandalone
                                  {...paginationProps}

                                />
                              </div>
                              <div className="pagination  mb-2">

                                <PaginationTotalStandalone
                                  {...paginationProps}
                                />
                              </div>

                            </Col>
                            <Col sm={6}>
                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>

                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {alert_confirm ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => handleIDeleteCallClicks(selected_id)}
            onCancel={() => {
              setAlert_confirm(false)
              setSelected_id(null)
            }
            }
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : null}

        {success_confirm ? (
          <SweetAlert
            success
            title={dynamic_title}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              setSuccess_confirm(false)
              setAlert_confirm(false)
            }
            }
          >
            {dynamic_description}
          </SweetAlert>
        )
          : null
        }

      </div>
      <Col sm={6} md={4} xl={3}>
        <Modal
          isOpen={modal_center}
          toggle={() => {
            tog_center()
          }}
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Recording</h5>
            <button
              type="button"
              onClick={() => {
                setmodal_center(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row>
              <Col md={10}>
                <div>
                  <audio autoPlay controls >

                    <source src={audio} type="audio/mp3" />
                    Your browser does not support the audio tag.
                  </audio>

                </div>


              </Col>
            </Row>

          </div>
        </Modal>

      </Col>

    </React.Fragment>
  )
}

export default CallArchive
