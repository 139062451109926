import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap"
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"

import { toast } from "react-toastify";
import 'toastr/build/toastr.min.css'
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken";
// import { HereFromOptions, YesNoNAOptions, YesNoOptions } from "../InitialScreening/Options";

import { BASE_URL } from "../../helpers/url_helper";
import { get, put } from "../../helpers/api_helper";
import ActivityLog from "../ActivityLog/ActivityLog";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


import { EditorState, convertToRaw, ContentState, left, right, center, justify, undo, redo, bold, italic, underline,
    ordered, indent, outdent, link, unlink, eraser, unordered } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";


const ContractorDetail = (props) => {

    const { id } = props.match.params

    const queryParams = new URLSearchParams(props.location.search);
    const type = queryParams.get('type'); // This will extract 'endorseme

    console.log(type, "props")

    const [contractor, setContractors] = useState(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty()); 
    const [rawMessage, setrawMessage] = useState(''); 

    const [selectedGroup, setselectedGroup] = useState({ label: "Select Agent", value: null });
    const [selectedState, setselectedState] = useState({ label: "Texas", value: 44 });
    const [didMount, setDidMount] = useState(false);
    const [states, setStates] = useState([]);
    const [contracortype, setContracortype] = useState([]);

    const [selectedCategory, setselectedCategory] = useState({ label: "Select Service", value: null })
    const [selectedSubCategories, setSelectedSubCategories] = useState([])
    const [categoryOptions, SetCategoryOptions] = useState([])
    const [subCategoryOptions, SetSubCategoryOptions] = useState([])
    const [phone, setPhone] = useState("")
    const [ppc_source, setppc_source] = useState("")
    const [agents, setAgents] = useState([]);
    const [logo, setLogo] = useState(null);
    const [img_1, setImg1] = useState(null);
    const [img_2, setImg2] = useState(null);
    const [img_3, setImg3] = useState(null);
    const [edit, setEdit] = useState(false);
    const [selectedHighlights, setSelectedHighlights] = useState([]);
    const [highlightsOptions, setHighlightsOptions] = useState([]);
    // const [slug, setSlug] = useState(null);

    useEffect(() => {
        setDidMount(true);
        loadState()
        loadContractor(false)
        loadAgents()
        loadHighLights()
        loadParentCategories()

        return () => setDidMount(false);

    }, []);



    function onEditorStateChange(editorState){
        setEditorState(editorState)
        setrawMessage(draftToHtml(convertToRaw(editorState.getCurrentContent())))
      };


    function loadHighLights() {
        get(`${BASE_URL}/api/tgcl/highlights/`, { headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                let options = []
                for (let i = 0; i < response.data.results.length; i++) {
                    options.push({ label: response.data.results[i].name, value: response.data.results[i].id })
                }
                let HighlightsOptions = [
                    {
                        label: "Highlights",
                        options: options
                    }]

                setHighlightsOptions(HighlightsOptions)
            })
    }


    function loadContractor(campigiondata) {
        let data = get(`${BASE_URL}/api/tgcl/contractor/${id}/`, { 'Content-Type': 'application/json' })
        data.then(response => {
            console.log(response, "REEEESSSS")

            props.setContractorType(response.data.contractor.contractor_type)
            props.setViewpoint(campigiondata)
            if (response.data.contractor.state) {
                setselectedState({ label: response.data.contractor.state.state, value: response.data.contractor.state.id_num })
            }
            if (response.data.contractor.contractor_type) {
                setContracortype({ label: response.data.contractor.contractor_type === "ENDORSEMENT_PARTNER" ? "Endorsement Partner" : "Directory Partner", value: response.data.contractor.contractor_type })
            }


            if (response.data.contractor.agent) {
                setselectedGroup({ label: response.data.contractor.agent.username, value: response.data.contractor.agent.id })
            }
            let highlights = []
            for (let i = 0; i < response.data.contractor.profile.highlights.length; i++) {
                highlights.push({ label: response.data.contractor.profile.highlights[i].name, value: response.data.contractor.profile.highlights[i].id })
            }
            let sub_categories = []
            for (let i = 0; i < response.data.contractor.sub_categories.length; i++) {
                sub_categories.push({ label: response.data.contractor.sub_categories[i].heading, value: response.data.contractor.sub_categories[i].id_num })
            }
            if (response.data.contractor.service) {
                setselectedCategory({ label: response.data.contractor.service.heading, value: response.data.contractor.service.id_num })
                loasChildCategories(response.data.contractor.service.id_num)
            }

            if (response.data.contractor.description){
                const { contentBlocks, entityMap } = htmlToDraft(response.data.contractor.description);
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                const editorrState = EditorState.createWithContent(contentState);
                setrawMessage(draftToHtml(convertToRaw(editorrState.getCurrentContent())))
                setEditorState(editorrState)
    
            }
    


            setSelectedSubCategories(sub_categories)
            setSelectedHighlights(highlights)
            setContractors(response.data.contractor)
            setPhone(response.data.contractor.phone)
            setppc_source(response.data.contractor.ppc_source)
            // setSlug(response.data.contractor.slug)
        },
            error => {
                // props.history.push('/contractors')
            })
    }

    function loadParentCategories() {
        get(`${BASE_URL}/api/tgcl/categories/?page_size=200`, { 'Content-Type': 'application/json' })
            .then(response => {
                let options = []
                let cat_data = response.data.results
                for (let i = 0; i < cat_data.length; i++) {

                    options.push({ label: cat_data[i].heading, value: cat_data[i].id_num })
                }

                SetCategoryOptions(options)
            },
                error => {
                    props.history.push('/contractors')

                })

    }

    function loasChildCategories(id, is_changed = null) {
        get(`${BASE_URL}/api/tgcl/headings/?parent_id=${id}&page_size=50`, { 'Content-Type': 'application/json' })
            .then(response => {
                let options = []
                let cat_data = response.data.results
                for (let i = 0; i < cat_data.length; i++) {

                    options.push({ label: cat_data[i].heading, value: cat_data[i].id_num })
                }

                SetSubCategoryOptions(options)
                if (is_changed) {
                    setSelectedSubCategories(options)
                }
            },
                error => {
                    props.history.push('/contractors')

                })

    }


    function loadState() {
        let data = get(`${BASE_URL}/api/tgcl/state/?page_size=200`, { 'Content-Type': 'application/json' })
        data.then(response => {
            let options = []
            let state_data = response.data.results
            for (let i = 0; i < state_data.length; i++) {
                options.push({ label: state_data[i].state, value: state_data[i].id_num })
            }

            setStates(options)
        },
            error => {
                props.history.push('/contractors')

            })

    }

    function loadAgents() {
        get(`${BASE_URL}/api/accounts/users/`, { 'Content-Type': 'application/json' })
            .then(response => {
                let options = []
                let agents_data = response.data.results
                for (let i = 0; i < agents_data.length; i++) {
                    options.push({ label: agents_data[i].username, value: agents_data[i].id })
                }
                setAgents(options)
            },
                error => {
                    props.history.push('/contractors')

                })

    }

    if (!didMount) {
        return null;
    }

    function handleSelectGroup(selectedGroup) {
        setselectedGroup(selectedGroup);
    }

    function handleSelectState(selectedState) {
        setselectedState(selectedState);
    }


    const States = [
        {
            label: "States",
            options: states
        },
    ];

    const Agents = [
        {
            label: "Agents",
            options: agents
        },
    ];

    const Categories = [
        {
            label: "Categories",
            options: categoryOptions
        },
    ];

    const ContractorOptions = [
        {
            label: "Select Type",
            options: [
                { label: "Directory Partner", value: "DIRECTORY_PARTNER" },
                { label: "Endorsement Partner", value: "ENDORSEMENT_PARTNER" }
            ]
        }
    ];



    function handleChange(e, setData) {
        setData(e.target.files[0]);
        // URL.createObjectURL
    }

    function HandleSubmitProfie(e, values) {
        let data = values
        let highlights = []
        for (let i = 0; i < selectedHighlights.length; i++) {
            highlights.push(selectedHighlights[i].value)
        }
        data['highlights'] = highlights
        data['contractor'] = id
        data['active'] = document.getElementById('active').checked
        put(`${BASE_URL}/api/tgcl/contractor-profile/${contractor.profile.id}/`, data, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken() }, validateStatus: false })
            .then(response => {
                toast.success("Campaign Updated Successfully")
            })

    }

    function addPhoneNumber(id) {
        let area_code = prompt("Please enter a three-digit area code:");
        if (area_code !== '' && area_code !== undefined) {
            get(`${BASE_URL}/api/tgcl/dashboard/add-phone-number/?area_code=${area_code}`, { 'Content-Type': 'application/json' })
                .then(response => {
                    if (response.status >= 400) {
                        console.log(response)
                        toast.error("Somthing went Wrong")

                    } else {
                        let num = response.data.friendly_name
                        num = num.replace("(", "")
                        num = num.replace(" ", "")
                        num = num.replace(")", "-")
                        if (id === "ppc") {
                            setppc_source(num)
                        } else {
                            setPhone(num)
                        }
                    }

                })
        }
        else {
            alert("Acquire canceled.");
        }

    }

    function releasePhoneNumber(id) {

        let number = phone
        if (id === "ppc") {
            number = ppc_source
        }
        if (number !== "") {
            number = number.replaceAll("-", "")
            number = number[0] === "+" ? number : `+1${number}`
            if (window.confirm("Are you sure you want to decomission this number?  This cannot be undone.")) {
                get(`${BASE_URL}/api/tgcl/dashboard/delete-phone-number/?phone_number=${number}`, { 'Content-Type': 'application/json' })
                    .then(response => {
                        if (response.status >= 400) {
                            console.log(response)
                            toast.error("Somthing went Wrong")
                        }
                    })
                if (id === "ppc") {
                    setppc_source("")
                } else {
                    setPhone("")
                }

            }

        }

    }

    function handleSubmit(e, values) {
        e.preventDefault(); // Prevent default form submission behavior
        
        const formData = new FormData();
    
        // Append images if present
        if (logo) formData.append('logo', logo, logo.name);
        if (img_1) formData.append('img_1', img_1, img_1.name);
        if (img_2) formData.append('img_2', img_2, img_2.name);
        if (img_3) formData.append('img_3', img_3, img_3.name);
    
        // Append subcategories
        selectedSubCategories.forEach(subCategory => {
            formData.append("sub_categories", subCategory.value);
        });
    
        // Append other selected values
        if (selectedGroup?.value) formData.append("agent", selectedGroup.value);
        if (selectedState?.value) formData.append("state", selectedState.value);
        if (selectedCategory?.value) formData.append("service", selectedCategory.value);
        if (contracortype?.value) formData.append("contractor_type", contracortype.value); // Fixed typo
    
        // Append form values
        Object.entries(values).forEach(([key, value]) => {
            formData.append(key, value);
        });
    
        // Append checkbox values
        formData.append("isactive", document.getElementById('isactive').checked ? 1 : 0);
        formData.append("callemail", document.getElementById('callemail').checked ? 1 : 0);
        formData.append("approved", document.getElementById('approved').checked ? 1 : 0);
        formData.append("archive", document.getElementById('archive').checked ? 1 : 0);
        formData.append('description', rawMessage)
        // Set up options for PATCH request
        const options = {
            method: 'PATCH',
            body: formData,
            headers: {
                'Authorization': getAccessToken(),
            }
        };
    
        // Debugging output
        console.log("Options:", options);
    
        // Fetch request to the server
        fetch(`${BASE_URL}/api/tgcl/contractor/${id}/`, options)
            .then(res => {
                if (res.status >= 400) {
                    return res.json().then(data => {
                        console.log("Error data:", data);
                        toast.error("Something went wrong");
                    });
                } else {
                    // Handle redirection based on contractor type and response type
                    if (type === "directory" || type === "endorsenemt" ) {
                        if (contracortype.value === contractor.contractor_type) {
                            props.history.push('/directory-contractors');
                        } else {
                            setEdit(false);
                            setContractors(null);
                            loadContractor(true);
                        }
                    } else if (type === "endorsement") {
                        if (contracortype.value === contractor.contractor_type) {
                            props.history.push('/endorsement-contractors');
                        } else {
                            setEdit(false);
                            setContractors(null);
                            loadContractor(true);
                        }
                    } else {

                        if (contracortype.value === contractor.contractor_type) {
                            props.history.push('/contractors');
                        } else {
                            setEdit(false);
                            setContractors(null);
                            loadContractor(true);
                        }

                      
                    }
                }
            })
            .catch(error => {
                console.error("Fetch error:", error);
                toast.error("Network error occurred");
            });
    
    

    }

    return (
        <React.Fragment>
            {/* <div className="page-content"> */}

            {/* <Breadcrumbs title="Contractors" breadcrumbItem="Add Contractor" link="/contractors" /> */}
            {contractor ?

                <Row>
                    <Col md="12">
                        {edit ?
                            <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                                <Row>
                                    <Col sm="6">
                                        <Card>
                                            <CardBody>
                                                <CardTitle>Contractor Detail </CardTitle>

                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="bus_name">Business Name</Label>
                                                            <AvField
                                                                name="bus_name"
                                                                placeholder="Business Name"
                                                                type="text"
                                                                errorMessage="Enter Business Name"
                                                                className="form-control"
                                                                id="bus_name"
                                                                // onChange={(e)=> setSlug(slugify(e.target.value))}
                                                                value={contractor?.bus_name}
                                                            />
                                                        </div>
                                                    </Col>

                                                </Row>
                                                {/* <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                        <Label htmlFor="slug">Slug</Label>
                                                        <AvField
                                                            name="slug"
                                                            placeholder="Slug"
                                                            type="text"
                                                            errorMessage="Enter Slug"
                                                            className="form-control"
                                                            value={slug}
                                                            validate={{ required: { value: true } }}
                                                            id="slug"
                                                            onChange={(e)=> setSlug(slugify(e.target.value))}
                                                        />
                                                        </div>
                                                    </Col>
                                                
                                                </Row> */}

                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="address">Address</Label>
                                                            <AvField
                                                                name="address"
                                                                placeholder="Address"
                                                                type="text"
                                                                errorMessage="Enter Address"
                                                                className="form-control"
                                                                id="address"
                                                                value={contractor?.address}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <AvField
                                                                className="mb-3"
                                                                name="email"
                                                                label="E-Mail"
                                                                placeholder="Enter Valid Email"
                                                                type="email"
                                                                errorMessage="Invalid Email"

                                                                value={contractor?.email}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label>State</Label>
                                                            <Select
                                                                value={selectedState}
                                                                onChange={(e) => {
                                                                    handleSelectState(e);
                                                                }}
                                                                options={States}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="validationCustom03">City</Label>
                                                            <AvField
                                                                name="city"
                                                                placeholder="City"
                                                                type="text"
                                                                errorMessage=" Please provide a valid city."
                                                                className="form-control"
                                                                id="validationCustom03"
                                                                value={contractor?.city}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="validationCustom05">Zip</Label>
                                                            <AvField
                                                                name="zip"
                                                                placeholder="Zip Code"
                                                                type="text"
                                                                errorMessage=" Please provide a valid zip."
                                                                className="form-control"
                                                                id="validationCustom05"
                                                                value={contractor?.zip}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>


                                                {(contracortype.value !== "ENDORSEMENT_PARTNER" ? false :


                                                    (phone === "" || phone === " " || phone === null || phone === undefined || phone === "undefined" || phone.length === 0 || Array.isArray(phone) && phone.length === 0 || typeof phone === "object" && Object.keys(phone).length === 0)
                                                ) ? true : false

                                                    ?
                                                    (null) : (

                                                        // else if (type === "endorsenemt") {
                                                        //     props.history.push('/endorsment-contractors')
                                                        // }else{
                                                        //     props.history.push('/contractors')
                                                        // }


                                                        <Row>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <AvField
                                                                        name="phone"
                                                                        label="Tracking Phone Number 1"
                                                                        type="text"
                                                                        errorMessage="Enter Only Digits"
                                                                        validate={{
                                                                            pattern: {
                                                                                value: "^[0-9+-]+$",
                                                                                errorMessage: "Invalid Contact"
                                                                            },
                                                                        }}
                                                                        value={phone}
                                                                        onChange={(e) => setPhone(e.target.value)}
                                                                    />
                                                                    <Link to={'#'} onClick={() => addPhoneNumber('phone')} className="text-primary" style={{ fontSize: '10px' }}>Acquire New Number</Link> | <Link to={'#'} onClick={() => releasePhoneNumber('phone')} className="text-primary" style={{ fontSize: '10px' }}>Release Number</Link>
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <AvField
                                                                        name="ppc_source"
                                                                        label="Tracking Phone Number 2"
                                                                        type="text"
                                                                        errorMessage="Enter Only Digits"
                                                                        validate={{
                                                                            pattern: {
                                                                                value: "^[0-9+-]+$",
                                                                                errorMessage: "Invalid Contact"
                                                                            },
                                                                        }}
                                                                        value={ppc_source}
                                                                        onChange={(e) => setppc_source(e.target.value)}
                                                                    />
                                                                    <Link to={'#'} onClick={() => addPhoneNumber('ppc')} className="text-primary" style={{ fontSize: '10px' }}>Acquire New Number</Link> | <Link to={'#'} onClick={() => releasePhoneNumber('ppc')} className="text-primary" style={{ fontSize: '10px' }}>Release Number</Link>

                                                                </div>
                                                            </Col>



                                                            {(contracortype.value !== "ENDORSEMENT_PARTNER") ? (null) : (<p style={{ color: "red" }}>

                                                                Tracking Phone Number is Already Assigned Please Relese Number as this is Endorsenemt Partner Contractor.
                                                            </p>)}



                                                        </Row>)}



                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="contact_name">Contact Name</Label>
                                                            <AvField
                                                                name="contact_name"
                                                                type="text"
                                                                errorMessage="Enter Contact Name"
                                                                className="form-control"
                                                                // validate={{ required: { value: true } }}
                                                                id="contact_name"
                                                                value={contractor?.contact_name}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <AvField
                                                                className="mb-3"
                                                                name="contact_email"
                                                                label="Contact E-Mail"
                                                                type="email"
                                                                errorMessage="Invalid Email"
                                                                validate={{
                                                                    // required: { value: true },
                                                                    email: { value: true },
                                                                }}
                                                                value={contractor?.contact_email}
                                                            />
                                                        </div>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <AvField
                                                                className="mb-3"
                                                                name="email2"
                                                                label="Additional E-Mail 1"
                                                                type="email"
                                                                errorMessage="Invalid Email"

                                                                value={contractor?.email2}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <AvField
                                                                className="mb-3"
                                                                name="email3"
                                                                label="Additional E-Mail 2"
                                                                type="email"
                                                                errorMessage="Invalid Email"

                                                                value={contractor?.email3}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="contact_phone"
                                                                label="Contact Phone 1"
                                                                type="text"
                                                                errorMessage="Enter Only Digits"
                                                                validate={{
                                                                    pattern: {
                                                                        value: "^[0-9+-]+$",
                                                                        errorMessage: "Invalid Contact"
                                                                    },
                                                                }}
                                                                value={contractor?.contact_phone}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="contact_phone_2"
                                                                label="Contact Phone 2"
                                                                type="text"
                                                                errorMessage="Enter Only Digits"
                                                                validate={{
                                                                    pattern: {
                                                                        value: "^[0-9+-]+$",
                                                                        errorMessage: "Invalid Contact"
                                                                    },
                                                                }}
                                                                value={contractor?.contact_phone_2}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                {/* <Row >
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="trucks">How many trucks/crews does your company have?</Label>
                                                            <AvField
                                                                name="trucks"
                                                                placeholder="Number of Trucks/Crews"
                                                                type="text"
                                                                className="form-control"
                                                                id="trucks"
                                                                value={contractor.trucks}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="established" >Year Business Established</Label>
                                                            <AvField
                                                                name="established"
                                                                placeholder="Year Business Established"
                                                                type="text"
                                                                className="form-control"
                                                                id="established"
                                                                value={contractor.established}
                                                                />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="exp">Years of Experience in Your Field</Label>
                                        
                                                            <AvField
                                                                name="exp"
                                                                placeholder="Years of Experience in Your Field"
                                                                type="text"
                                                                className="form-control"
                                                                id="exp"
                                                                value={contractor.exp}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row> */}


                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col sm="6">
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="url">URL</Label>
                                                            <AvField
                                                                name="url"
                                                                type="text"
                                                                errorMessage="Enter URL"
                                                                className="form-control"
                                                                // validate={{ required: { value: true } }}
                                                                id="url"
                                                                value={contractor?.url}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="license">License</Label>
                                                            <AvField
                                                                name="license"
                                                                type="text"
                                                                errorMessage="Enter License"
                                                                className="form-control"
                                                                // validate={{ required: { value: true } }}
                                                                id="license"
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label>Agent</Label>
                                                            <Select
                                                                value={selectedGroup}
                                                                onChange={(e) => {
                                                                    handleSelectGroup(e);
                                                                }}
                                                                options={Agents}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">

                                                        <div className="mb-3">
                                                            <Label htmlFor="service" >Field of service such as Air Conditioning, Plumbing, etc</Label>

                                                            <Select
                                                                value={selectedCategory}
                                                                onChange={(e) => {
                                                                    setselectedCategory(e)
                                                                    setSelectedSubCategories([])
                                                                    loasChildCategories(e.value, true)
                                                                    SetSubCategoryOptions([])
                                                                }}
                                                                options={Categories}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>


                                                    <Col md="12">

                                                        <div className="mb-3">
                                                            <Label htmlFor="service" >Type of Contractor</Label>

                                                            <Select
                                                                value={contracortype}
                                                                onChange={(e) => {
                                                                    setContracortype(e)
                                                                }}
                                                                options={ContractorOptions}
                                                                classNamePrefix="select2-selection"

                                                            />

                                                        </div>
                                                    </Col>


                                                </Row>
                                                <Row>
                                                    <Col md="12">

                                                        <div className="mb-3">
                                                            <Label htmlFor="service" >Sub Categories</Label>

                                                            <Select
                                                                value={selectedSubCategories}
                                                                onChange={(e) => {
                                                                    setSelectedSubCategories(e)
                                                                }}
                                                                isMulti={true}
                                                                options={subCategoryOptions}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>

                                                </Row>


                                                {(contracortype.value !== "ENDORSEMENT_PARTNER") ? (null) : (
                                                <Col md="12" className="mb-3">
                                            <Label >Description / Narrative</Label>
                                                <Editor  
                                               
                                                toolbar={{
                                                    options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'remove', 'history'],
                                                    inline: {
                                                      inDropdown: false,
                                                      className: undefined,
                                                      component: undefined,
                                                      dropdownClassName: undefined,
                                                      options: ['bold', 'italic', 'underline'],
                                                      bold: { icon: bold, className: undefined },
                                                      italic: { icon: italic, className: undefined },
                                                      underline: { icon: underline, className: undefined },
                                                    },
                                                    blockType: {
                                                      inDropdown: true,
                                                      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                                                      className: undefined,
                                                      component: undefined,
                                                      dropdownClassName: undefined,
                                                    },
                                                
                                                    list: {
                                                      inDropdown: false,
                                                      className: undefined,
                                                      component: undefined,
                                                      dropdownClassName: undefined,
                                                      options: ['unordered', 'ordered', 'indent', 'outdent'],
                                                      unordered: { icon: unordered, className: undefined },
                                                      ordered: { icon: ordered, className: undefined },
                                                      indent: { icon: indent, className: undefined },
                                                      outdent: { icon: outdent, className: undefined },
                                                    },
                                                    textAlign: {
                                                      inDropdown: false,
                                                      className: undefined,
                                                      component: undefined,
                                                      dropdownClassName: undefined,
                                                      options: ['left', 'center', 'right', 'justify'],
                                                      left: { icon: left, className: undefined },
                                                      center: { icon: center, className: undefined },
                                                      right: { icon: right, className: undefined },
                                                      justify: { icon: justify, className: undefined },
                                                    },
                                                    
                                                    link: {
                                                      inDropdown: false,
                                                      className: undefined,
                                                      component: undefined,
                                                      popupClassName: undefined,
                                                      dropdownClassName: undefined,
                                                      showOpenOptionOnHover: true,
                                                      defaultTargetOption: '_self',
                                                      options: ['link', 'unlink'],
                                                      link: { icon: link, className: undefined },
                                                      unlink: { icon: unlink, className: undefined },
                                                      linkCallback: undefined
                                                    },
                                                    remove: { icon: eraser, className: undefined, component: undefined },
                                                    history: {
                                                      inDropdown: false,
                                                      className: undefined,
                                                      component: undefined,
                                                      dropdownClassName: undefined,
                                                      options: ['undo', 'redo'],
                                                      undo: { icon: undo, className: undefined },
                                                      redo: { icon: redo, className: undefined },
                                                    },
                                                  }}      
                                                editorState={editorState}            
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={onEditorStateChange}
                                                />
                                            </Col>)}
                                 



                                                {/* <Row >
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="url" >Accredited Since in BBB Business</Label>
                                        
                                                            <AvField
                                                                name="Accredited Since"
                                                                placeholder="Since"
                                                                type="date"
                                                                className="form-control"
                                                                id="since"
                                                                value={contractor.since}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                
                                                <Row >
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="bbb_url" >Better Business Bureau URL</Label>
                            
                                                            <AvField
                                                                name="bbb_url"
                                                                placeholder="Better Business Bureau URL"
                                                                type="text"
                                                                className="form-control"
                                                                id="bbb_url"
                                                                value={contractor.bbb_url}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="bbb" >Better Business Bureau Standing</Label>
                            
                                                            <AvField
                                                                name="bbb"
                                                                placeholder="Better Business Bureau Standing"
                                                                type="text"
                                                                className="form-control"
                                                                id="bbb"
                                                                value={contractor.bbb}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="genlib">Amount of General Liability Carried</Label>
                                                        
                                                            <AvField
                                                                name="genlib"
                                                                placeholder="Amount of General Liability Carried"
                                                                type="text"
                                                                className="form-control"
                                                                id="genlib"
                                                                value={contractor.genlib}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row >
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="genlib">Do you offer a written 100% Satisfaction Guarantee to your clients?:</Label>
                                                    
                                                            <Select
                                                                // className="form-control"
                                                                value={guarantee}
                                                                onChange={(e) => {setGuarantee(e)}}
                                                                options={YesNoNAOptions}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>    
                                                </Row>
                                                <Row >
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="genlib" >Can you provide at least 3 industry professionals that can vouch for your long-term integrity and quality?:</Label>
                                                    
                                                            <Select
                                                                // className="form-control"
                                                                value={refs}
                                                                onChange={(e) => {
                                                                    setRefs(e)
                                                                }}
                                                                options={YesNoOptions}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="genlib" >Are you willing to undergo background investigations?:</Label>
                                                    
                                                            <Select
                                                                // className="form-control"
                                                                value={bgcheck}
                                                                onChange={(e) => {
                                                                    setBgcheck(e)
                                                                }}
                                                                options={YesNoOptions}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="genlib" >How did you hear of us?</Label>
                                                    
                                                            <Select
                                                                // className="form-control"
                                                                value={hear_from}
                                                                onChange={(e) => {
                                                                    setHear_from(e)
                                                                }}
                                                                options={HereFromOptions}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="genlib" >Are your technicians paid a commissions</Label>
                                                        
                                                            <Select
                                                                // className="form-control"
                                                                value={pay_comm}
                                                                onChange={(e) => {
                                                                    setPay_comm(e)
                                                                }}
                                                                options={YesNoNAOptions}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="no_pressure" >If YES, how do you insure your technicians are not using fear tactics or high pressure to increase their paycheck</Label>
                                                            <AvField
                                                                className="mb-3"
                                                                type="textarea"
                                                                rows="3"
                                                                name="no_pressure"
                                                                id="no_pressure"
                                                                value={contractor.no_pressure}
                                                                placeholder="If YES, how do you insure your technicians are not using fear tactics or high pressure to increase their paycheck"
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="areas_served" >What area(s) do you serve?</Label>
                                                            <AvField
                                                                className="mb-3"
                                                                type="textarea"
                                                                rows="3"
                                                                name="areas_served"
                                                                id="areas_served"
                                                                placeholder="What area(s) do you serve?"
                                                                value={contractor.areas_served}
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="differences" >List any details separating your business from your competition?</Label>
                                                            <AvField
                                                                className="mb-3"
                                                                type="textarea"
                                                                rows="3"
                                                                name="differences"
                                                                id="differences"
                                                                placeholder="List any details separating your business from your competition?"
                                                                value={contractor.differences}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row> */}
                                                <Row className="mt-3">
                                                    <Col md="3">
                                                        <div className="form-check mb-3">
                                                            <input className="form-check-input" type="checkbox" defaultChecked={contractor.isactive === 1 ? true : false} id="isactive"
                                                                required />{" "}
                                                            <label className="form-check-label" htmlFor="isactive">
                                                                Active</label>
                                                        </div>
                                                    </Col>
                                                    <Col md="3">
                                                        <div className="form-check mb-3">
                                                            <input className="form-check-input" type="checkbox" defaultChecked={contractor.approved} id="approved"
                                                                required />{" "}
                                                            <label className="form-check-label" htmlFor="approved">
                                                                Approved</label>
                                                        </div>
                                                    </Col>
                                                    <Col md="3">
                                                        <div className="form-check mb-3">
                                                            <input className="form-check-input" type="checkbox" defaultChecked={contractor.callemail === 1 ? true : false} id="callemail"
                                                                required />{" "}
                                                            <label className="form-check-label" htmlFor="callemail">
                                                                Call Notification</label>
                                                        </div>
                                                    </Col>
                                                    <Col md="3">
                                                        <div className="form-check mb-3">
                                                            <input className="form-check-input" type="checkbox" defaultChecked={contractor.archive} id="archive"
                                                                required />{" "}
                                                            <label className="form-check-label" htmlFor="archive">
                                                                Archived</label>
                                                        </div>
                                                    </Col>
                                                </Row>



                                                <Row>
                                                    <Label htmlFor="icon">Logo</Label>
                                                    <Col md="6">
                                                        <input id="logo" className="mb-3" type="file" onChange={(e) => handleChange(e, setLogo)} />
                                                    </Col>
                                                    <Col md="6">
                                                        {logo ?
                                                            <img src={URL.createObjectURL(logo)} alt="" style={{ width: "40%" }} className="m-3" />
                                                            : contractor.logo ?
                                                                <img src={contractor.logo} alt="" style={{ width: "40%" }} className="m-3" />
                                                                : null}
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Label htmlFor="icon">Image 1</Label>
                                                    <Col md="6">
                                                        <input id="img_1" className="mb-3" type="file" onChange={(e) => handleChange(e, setImg1)} />
                                                    </Col>
                                                    <Col md="6">
                                                        {img_1 ?
                                                            <img src={URL.createObjectURL(img_1)} alt="icon" style={{ width: "40%" }} className="m-3" />
                                                            : contractor.img_1 ?
                                                                <img src={contractor.img_1} alt="icon" style={{ width: "40%" }} className="m-3" />
                                                                : null}
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Label htmlFor="icon">Image 2</Label>
                                                    <Col md="6">
                                                        <input id="img_2" className="mb-3" type="file" onChange={(e) => handleChange(e, setImg2)} />
                                                    </Col>
                                                    <Col md="6">
                                                        {img_2 ?
                                                            <img src={URL.createObjectURL(img_2)} alt="icon" style={{ width: "40%" }} className="m-3" />
                                                            : contractor.img_2 ?
                                                                <img src={contractor.img_2} alt="icon" style={{ width: "40%" }} className="m-3" />
                                                                : null}
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Label htmlFor="icon">Image 3</Label>
                                                    <Col md="6">
                                                        <input id="img_3" className="mb-3" type="file" onChange={(e) => handleChange(e, setImg3)} />
                                                    </Col>
                                                    <Col md="6">
                                                        {img_3 ?
                                                            <img src={URL.createObjectURL(img_3)} alt="icon" style={{ width: "40%" }} className="m-3" />
                                                            : contractor.img_3 ?
                                                                <img src={contractor.img_3} alt="icon" style={{ width: "40%" }} className="m-3" />
                                                                : null}
                                                    </Col>

                                                </Row>

                                                <Button color="primary" type="submit">
                                                    Submit
                                                </Button>


                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                            </AvForm>
                            :
                            <AvForm className="needs-validation">
                                <Row>
                                    <Col sm="6">
                                        <Card>
                                            <CardBody>
                                                <CardTitle>Contractor Detail </CardTitle>

                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="bus_name">Business Name</Label>
                                                            <AvField
                                                                name="bus_name"
                                                                type="text"
                                                                className="form-control"
                                                                id="bus_name"
                                                                value={contractor?.bus_name}
                                                                disabled
                                                            />
                                                        </div>
                                                    </Col>

                                                </Row>
                                                {/* <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                        <Label htmlFor="slug">Slug</Label>
                                                        <AvField
                                                            name="slug"
                                                            placeholder="Slug"
                                                            type="text"
                                                            errorMessage="Enter Slug"
                                                            className="form-control"
                                                            value={slug}
                                                            validate={{ required: { value: true } }}
                                                            id="slug"
                                                            onChange={(e)=> setSlug(slugify(e.target.value))}
                                                            disabled
                                                        />
                                                        </div>
                                                    </Col>
                                                
                                                </Row> */}
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="address">Address</Label>
                                                            <AvField
                                                                name="address"
                                                                type="text"
                                                                className="form-control"
                                                                id="address"
                                                                value={contractor?.address}
                                                                disabled
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <AvField
                                                                className="mb-3"
                                                                name="email"
                                                                label="E-Mail"
                                                                type="email"
                                                                disabled
                                                                value={contractor?.email}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label>State</Label>
                                                            <Select
                                                                value={selectedState}
                                                                onChange={(e) => {
                                                                    handleSelectState(e);
                                                                }}
                                                                options={States}
                                                                classNamePrefix="select2-selection"
                                                                isDisabled={true}
                                                            />
                                                        </div>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="validationCustom03">City</Label>
                                                            <AvField
                                                                name="city"
                                                                type="text"
                                                                className="form-control"
                                                                id="validationCustom03"
                                                                value={contractor?.city}
                                                                disabled
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="validationCustom05">Zip</Label>
                                                            <AvField
                                                                name="zip"
                                                                type="text"
                                                                className="form-control"
                                                                id="validationCustom05"
                                                                value={contractor?.zip}
                                                                disabled
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>

                                                {(contracortype.value !== "ENDORSEMENT_PARTNER" ? false :
                                                    (phone === "" || phone === " " || phone === null || phone === undefined || phone === "undefined" || phone.length === 0 || Array.isArray(phone) && phone.length === 0 || typeof phone === "object" && Object.keys(phone).length === 0)
                                                ) ? true : false ?

                                                    (null) : (

                                                        <Row>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <AvField
                                                                        name="phone"
                                                                        label="Tracking Phone Number 1"
                                                                        type="text"
                                                                        disabled
                                                                        value={contractor?.phone}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={6}>
                                                                <div className="mb-3">
                                                                    <AvField
                                                                        name="ppc_source"
                                                                        label="Tracking Phone Number 2"
                                                                        type="text"
                                                                        disabled
                                                                        value={contractor?.ppc_source}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            {(contracortype.value !== "ENDORSEMENT_PARTNER") ? (null) : (<p style={{ color: "red" }}>

                                                                Tracking Phone Number is Already Assigned Please Relese Number as this is Endorsenemt Partner Contractor.
                                                            </p>)}
                                                        </Row>

                                                    )}

                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="contact_name">Contact Name</Label>
                                                            <AvField
                                                                name="contact_name"
                                                                type="text"
                                                                className="form-control"
                                                                id="contact_name"
                                                                value={contractor?.contact_name}
                                                                disabled
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <AvField
                                                                className="mb-3"
                                                                name="contact_email"
                                                                label="Contact E-Mail"
                                                                type="email"
                                                                disabled
                                                                value={contractor?.contact_email}
                                                            />
                                                        </div>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <AvField
                                                                className="mb-3"
                                                                name="email2"
                                                                label="Additional E-Mail 1"
                                                                type="email"
                                                                disabled
                                                                value={contractor?.email2}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <AvField
                                                                className="mb-3"
                                                                name="email3"
                                                                label="Additional E-Mail 2"
                                                                type="email"
                                                                disabled
                                                                value={contractor?.email3}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="contact_phone"
                                                                label="Contact Phone 1"
                                                                type="text"
                                                                disabled
                                                                value={contractor?.contact_phone}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <AvField
                                                                name="contact_phone_2"
                                                                label="Contact Phone 2"
                                                                type="text"
                                                                disabled
                                                                value={contractor?.contact_phone_2}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {/* <Row>
                                                        <Col md="12">
                                                            <div className="mb-3">
                                                            <Label htmlFor="username">Username</Label>
                                                            <AvField
                                                                name="user_name"
                                                                placeholder="Username"
                                                                type="text"
                                                                className="form-control"
                                                                id="username"
                                                                value={contractor?.user_name}
                                                                disabled
                                                            />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm="6">
                                                            <Label>Password</Label>
                                                            <AvField
                                                                name="passwrd"
                                                                type="password"
                                                                placeholder="Password"
                                                                value={contractor.passwrd}
                                                                disabled
                                                            />
                                                        </Col>
                                                        <Col sm="6">
                                                            <Label>Re-type Password</Label>
                                                            <AvField
                                                                name="password1"
                                                                type="password"
                                                                placeholder="Re-type Password"
                                                                value={contractor.passwrd}
                                                                disabled
                                                                
                                                                />
                                                        </Col>
                                                    
                                                    </Row> */}

                                                {/* <Row >
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="trucks">How many trucks/crews does your company have?</Label>
                                                            <AvField
                                                                name="trucks"
                                                                placeholder="Number of Trucks/Crews"
                                                                type="number"
                                                                className="form-control"
                                                                id="trucks"
                                                                value={contractor.trucks}
                                                                disabled
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="established" >Year Business Established</Label>
                                                            <AvField
                                                                name="established"
                                                                placeholder="Year Business Established"
                                                                type="number"
                                                                className="form-control"
                                                                id="established"
                                                                value={contractor.established}
                                                                disabled
                                                                />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="exp">Years of Experience in Your Field</Label>
                                        
                                                            <AvField
                                                                name="exp"
                                                                placeholder="Years of Experience in Your Field"
                                                                type="number"
                                                                className="form-control"
                                                                id="exp"
                                                                value={contractor.exp}
                                                                disabled
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                     */}

                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col sm="6">
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="url">URL</Label>
                                                            <AvField
                                                                name="url"
                                                                type="text"
                                                                className="form-control"
                                                                id="url"
                                                                value={contractor?.url}
                                                                disabled
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label htmlFor="license">License</Label>
                                                            <AvField
                                                                name="license"
                                                                type="text"
                                                                className="form-control"
                                                                id="license"
                                                                disabled
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label>Agent</Label>
                                                            <Select
                                                                value={selectedGroup}
                                                                onChange={(e) => {
                                                                    handleSelectGroup(e);
                                                                }}
                                                                options={Agents}
                                                                classNamePrefix="select2-selection"
                                                                isDisabled={true}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">

                                                        <div className="mb-3">
                                                            <Label htmlFor="service" >Field of service such as Air Conditioning, Plumbing, etc</Label>

                                                            <Select
                                                                value={selectedCategory}
                                                                onChange={(e) => {
                                                                    setselectedCategory(e)
                                                                    setSelectedSubCategories([])
                                                                    SetSubCategoryOptions([])
                                                                }}
                                                                options={Categories}
                                                                classNamePrefix="select2-selection"
                                                                isDisabled={true}
                                                            />

                                                        </div>
                                                    </Col>


                                                    <Col md="12">

                                                        <div className="mb-3">
                                                            <Label htmlFor="service" >Type of Contractor</Label>

                                                            <Select
                                                                value={contracortype}
                                                                onChange={(e) => {
                                                                    setContracortype(e)

                                                                }}
                                                                options={ContractorOptions}
                                                                classNamePrefix="select2-selection"
                                                                isDisabled={true}
                                                            />

                                                        </div>
                                                    </Col>



                                                </Row>
                                                <Row>
                                                    <Col md="12">

                                                        <div className="mb-3">
                                                            <Label htmlFor="service" >Sub Categories</Label>

                                                            <Select
                                                                value={selectedSubCategories}
                                                                onChange={(e) => {
                                                                    setSelectedSubCategories(e)
                                                                }}
                                                                isMulti={true}
                                                                options={subCategoryOptions}
                                                                classNamePrefix="select2-selection"
                                                                isDisabled={true}
                                                            />

                                                        </div>
                                                    </Col>

                                                </Row>

                                                {(contracortype.value !== "ENDORSEMENT_PARTNER") ? (null) : (
                                                <Col md="12" className="mb-3">
                                            <Label >Description / Narrative</Label>
                                                <Editor  
                                                  readOnly={true} // Disable user interaction
                                                  toolbarHidden={true} // Optionally hide the toolbar
                                                toolbar={{
                                                    options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'remove', 'history'],
                                                    inline: {
                                                      inDropdown: false,
                                                      className: undefined,
                                                      component: undefined,
                                                      dropdownClassName: undefined,
                                                      options: ['bold', 'italic', 'underline'],
                                                      bold: { icon: bold, className: undefined },
                                                      italic: { icon: italic, className: undefined },
                                                      underline: { icon: underline, className: undefined },
                                                    },
                                                    blockType: {
                                                      inDropdown: true,
                                                      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                                                      className: undefined,
                                                      component: undefined,
                                                      dropdownClassName: undefined,
                                                    },
                                                
                                                    list: {
                                                      inDropdown: false,
                                                      className: undefined,
                                                      component: undefined,
                                                      dropdownClassName: undefined,
                                                      options: ['unordered', 'ordered', 'indent', 'outdent'],
                                                      unordered: { icon: unordered, className: undefined },
                                                      ordered: { icon: ordered, className: undefined },
                                                      indent: { icon: indent, className: undefined },
                                                      outdent: { icon: outdent, className: undefined },
                                                    },
                                                    textAlign: {
                                                      inDropdown: false,
                                                      className: undefined,
                                                      component: undefined,
                                                      dropdownClassName: undefined,
                                                      options: ['left', 'center', 'right', 'justify'],
                                                      left: { icon: left, className: undefined },
                                                      center: { icon: center, className: undefined },
                                                      right: { icon: right, className: undefined },
                                                      justify: { icon: justify, className: undefined },
                                                    },
                                                    
                                                    link: {
                                                      inDropdown: false,
                                                      className: undefined,
                                                      component: undefined,
                                                      popupClassName: undefined,
                                                      dropdownClassName: undefined,
                                                      showOpenOptionOnHover: true,
                                                      defaultTargetOption: '_self',
                                                      options: ['link', 'unlink'],
                                                      link: { icon: link, className: undefined },
                                                      unlink: { icon: unlink, className: undefined },
                                                      linkCallback: undefined
                                                    },
                                                    remove: { icon: eraser, className: undefined, component: undefined },
                                                    history: {
                                                      inDropdown: false,
                                                      className: undefined,
                                                      component: undefined,
                                                      dropdownClassName: undefined,
                                                      options: ['undo', 'redo'],
                                                      undo: { icon: undo, className: undefined },
                                                      redo: { icon: redo, className: undefined },
                                                    },
                                                  }}      
                                                editorState={editorState}            
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={onEditorStateChange}
                                                />
                                            </Col>)}
                                 




                                                <Row className="mt-3">
                                                    <Col md="3">
                                                        <div className="form-check mb-3">
                                                            <input className="form-check-input" type="checkbox" disabled defaultChecked={contractor.isactive === 1 ? true : false} id="isactive"
                                                                required />{" "}
                                                            <label className="form-check-label" htmlFor="isactive">
                                                                Active</label>
                                                        </div>
                                                    </Col>
                                                    <Col md="3">
                                                        <div className="form-check mb-3">
                                                            <input className="form-check-input" type="checkbox" disabled defaultChecked={contractor.approved} id="approved"
                                                            />{" "}
                                                            <label className="form-check-label" htmlFor="approved">
                                                                Approved</label>
                                                        </div>
                                                    </Col>
                                                    <Col md="3">
                                                        <div className="form-check mb-3">
                                                            <input className="form-check-input" type="checkbox" disabled defaultChecked={contractor.callemail === 1 ? true : false} id="callemail"
                                                                required />{" "}
                                                            <label className="form-check-label" htmlFor="callemail">
                                                                Call Notification</label>
                                                        </div>
                                                    </Col>
                                                    <Col md="3">
                                                        <div className="form-check mb-3">
                                                            <input className="form-check-input" type="checkbox" disabled defaultChecked={contractor.archive} id="archive"
                                                                required />{" "}
                                                            <label className="form-check-label" htmlFor="archive">
                                                                Archived</label>
                                                        </div>
                                                    </Col>
                                                </Row>


                                                {contractor.logo ?
                                                    <Row>
                                                        <Label htmlFor="icon">Logo</Label>
                                                        <Col md="6">

                                                            <img src={contractor.logo} alt="" style={{ width: "40%" }} className="m-3" />

                                                        </Col>

                                                    </Row>
                                                    : null}
                                                {contractor.img_1 ?
                                                    <Row>
                                                        <Label htmlFor="icon">Image 1</Label>
                                                        <Col md="6">
                                                            <img src={contractor.img_1} alt="" style={{ width: "40%" }} className="m-3" />
                                                        </Col>
                                                    </Row>
                                                    : null}
                                                {contractor.img_2 ?
                                                    <Row>
                                                        <Label htmlFor="icon">Image 2</Label>
                                                        <Col md="6">
                                                            <img src={contractor.img_2} alt="" style={{ width: "40%" }} className="m-3" />

                                                        </Col>
                                                    </Row>
                                                    : null}
                                                {contractor.img_3 ?
                                                    <Row>
                                                        <Label htmlFor="icon">Image 3</Label>
                                                        <Col md="6">

                                                            <img src={contractor.img_3} alt="" style={{ width: "40%" }} className="m-3" />
                                                        </Col>
                                                    </Row>
                                                    : null}

                                                <p style={{ cursor: "pointer" }} onClick={() => setEdit(true)} className="me-3 text-primary float-end"><i className="mdi mdi-pencil"></i> Edit</p>


                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                            </AvForm>
                        }
                    </Col>
                    <Row>
                        <Col sm="6">
                            <Card>
                                <CardBody>
                                    <CardTitle>Additional Detail </CardTitle>
                                    <AvForm className="needs-validation" onValidSubmit={HandleSubmitProfie}>
                                        <Row className="mb-3">
                                            <Label htmlFor="email_subject" className="col-md-3 col-form-label">Monday:</Label>
                                            <div className="col-md-9">
                                                <AvField
                                                    name="monday"
                                                    placeholder="Monday"
                                                    type="text"
                                                    errorMessage="Enter Time"
                                                    className="form-control"
                                                    id="monday"
                                                    value={contractor.profile?.monday}
                                                />
                                            </div>

                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="email_subject" className="col-md-3 col-form-label">Tuesday:</Label>
                                            <div className="col-md-9">
                                                <AvField
                                                    name="tuesday"
                                                    placeholder="Tuesday"
                                                    type="text"
                                                    errorMessage="Enter Time"
                                                    className="form-control"
                                                    id="tuesday"
                                                    value={contractor.profile?.tuesday}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="email_subject" className="col-md-3 col-form-label">Wednesday:</Label>
                                            <div className="col-md-9">
                                                <AvField
                                                    name="wednesday"
                                                    placeholder="Wednesday"
                                                    type="text"
                                                    errorMessage="Enter Time"
                                                    className="form-control"
                                                    id="wednesday"
                                                    value={contractor.profile?.wednesday}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="email_subject" className="col-md-3 col-form-label">Thursday:</Label>
                                            <div className="col-md-9">
                                                <AvField
                                                    name="thursday"
                                                    placeholder="Thursday"
                                                    type="text"
                                                    errorMessage="Enter Time"
                                                    className="form-control"
                                                    id="thursday"
                                                    value={contractor.profile?.thursday}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="email_subject" className="col-md-3 col-form-label">Friday:</Label>
                                            <div className="col-md-9">
                                                <AvField
                                                    name="friday"
                                                    placeholder="Friday"
                                                    type="text"
                                                    errorMessage="Enter Time"
                                                    className="form-control"
                                                    id="friday"
                                                    value={contractor.profile?.friday}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="email_subject" className="col-md-3 col-form-label">Saturday:</Label>
                                            <div className="col-md-9">
                                                <AvField
                                                    name="saturday"
                                                    placeholder="Saturday"
                                                    type="text"
                                                    errorMessage="Enter Time"
                                                    className="form-control"
                                                    id="saturday"
                                                    value={contractor.profile?.saturday}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="email_subject" className="col-md-3 col-form-label">Sunday:</Label>
                                            <div className="col-md-9">
                                                <AvField
                                                    name="sunday"
                                                    placeholder="Sunday"
                                                    type="text"
                                                    errorMessage="Enter Time"
                                                    className="form-control"
                                                    id="sunday"
                                                    value={contractor.profile?.sunday}
                                                />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <Label htmlFor="email_subject" className="col-md-3 col-form-label">Highlites:</Label>
                                            <div className="col-md-9">
                                                <Select
                                                    menuPlacement="top"
                                                    value={selectedHighlights}
                                                    isMulti={true}
                                                    onChange={(e) => {
                                                        setSelectedHighlights(e)
                                                    }}
                                                    options={highlightsOptions}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>

                                        </Row>
                                        <Row className="mt-3">
                                            <Col md="3">
                                                <div className="form-check mb-3">
                                                    <input className="form-check-input" type="checkbox" defaultChecked={contractor.profile.active} id="active"
                                                        required />{" "}
                                                    <label className="form-check-label" htmlFor="active">
                                                        Active</label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Button color="primary" type="submit">
                                            Submit
                                        </Button>


                                    </AvForm>




                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                    <ActivityLog
                        id={id}
                        url={'/api/tgcl/contractor-logs/'}
                        filter={'contractor'} />
                </Row>

                :
                <div id="preloader">
                    <div id="status">
                        <div className="spinner-chase">
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                        </div>
                    </div>
                </div>
            }
            {/* </div> */}

        </React.Fragment>

    )
}

export default ContractorDetail
