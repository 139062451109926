import PropTypes from "prop-types"
import React, { useEffect, useRef , useCallback} from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }
    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement
      if (parent2) {
        parent2.classList.add("mm-show") // ul tag
        const parent3 = parent2.parentElement // li tag
        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }, []);
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname
    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname, activateParentDropdown])
  useEffect(() => {
    ref.current.recalculate()
  }, []);
  const scrollElement = (item) => {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  

  return (
    <React.Fragment>
      <SimpleBar ref={ref} className="vertical-simplebar">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/dashboard" className="waves-effect">
              <i className="mdi mdi-desktop-mac-dashboard"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="fas fa-users"></i>
                <span>{props.t("Users")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/customers">{props.t("Customers")}</Link>
                </li>
                <li>
                  <Link to="/contractors">{props.t("Contractors")}</Link>
                </li>               
              </ul>
            </li>


            <li>
              <Link to="/#" className="has-arrow waves-effect">
                {/* <i className="fas fa-users"></i> */}
                <i className="fas fa-hammer"></i>
                <span>{props.t("Contractor Partner")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/endorsment-contractors">{props.t("Endorsement Contractor Partner")}</Link>
                </li>
                <li>
                  <Link to="/directory-contractors">{props.t("Directory Contractor Partner")}</Link>
                </li>               
              </ul>
            </li>


            <li>
              <Link to="/comm-notes" className=" waves-effect">
                <i className="fas fa-sticky-note"></i>
                <span>{props.t("Comm. Notes")}</span>
              </Link>
            </li>

            <li>
              <Link to="/email-forms" className=" waves-effect">
                <i className="mdi mdi-inbox-full"></i>
                <span>{props.t("Email Forms")}</span>
              </Link>
            </li>
            <li>
              <Link to="/call-archive" className=" waves-effect">
                <i className="fas fa-phone"></i>
                <span>{props.t("Call Archive")}</span>
              </Link>
            </li>
            <li>
              <Link to="/categories" className=" waves-effect">
                <i className="fas fa-list-alt"></i>
                <span>{props.t("Categories")}</span>
              </Link>
            </li>
            <li>
              <Link to="/campaigns" className=" waves-effect">
                <i className="fas fa-file-contract"></i>
                <span>{props.t("Campaign")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="fas fa-comments"></i>
                <span>{props.t("Feedback")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/contractors-feedback">{props.t("Contractors Feedback")}</Link>
                </li>
                <li>
                  <Link to="/feedback-questions">{props.t("Feedback Questions")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="far fa-file-excel"></i>
                <span>{props.t("Reports")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/master-sold-report">{props.t("Master Sold Report")}</Link>
                </li>
                <li>
                  <Link to="/financial-report">{props.t("Financial Report")}</Link>
                </li>
                <li>
                  <Link to="/zone-sold-report">{props.t("Zone Report")}</Link>
                </li>
                <li>
                  <Link to="/zip-sold-report">{props.t("Zip Report")}</Link>
                </li>
                <li>
                  <Link to="/call-report">{props.t("Call Report")}</Link>
                </li>
                <li>
                  <Link to="/users-report">{props.t("Users Report")}</Link>
                </li>
                <li>
                  <Link to="/media-partner-referral-report">{props.t("Media Partner Referral Report")}</Link>
                </li>


                <li>
                  <Link to="/contractor-users-refer-report">{props.t("Contractor Users Referral Report")}</Link>
                </li>


                <li>
                  <Link to="/contractor-referral-report">{props.t("Contractor Referral Report")}</Link>
                </li>
                
                <li>
                  <Link to="/contractors-report">{props.t("Contractors Report")}</Link>
                </li>
                <li>
                  <Link to="/campaigns-report">{props.t("Campaigns Report")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="fas fa-tasks"></i>
                <span>{props.t("Cron Jobs")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/comm-notes-cron-job">{props.t("Call Notes")}</Link>
                </li>
                <li>
                  <Link to="/comm-notes-archive-cron-job">{props.t("Call Notes Archive")}</Link>
                </li>
              </ul>
            </li>
            
            <li>
              <Link to="/admins" className=" waves-effect">
                <i className="fas fa-user-tie"></i>
                <span>{props.t("Admins")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="fas fa-cogs"></i>
                <span>{props.t("Settings")}</span>
              </Link>
              <ul className="sub-menu">
                
                <li>
                  <Link to="/highlights">{props.t("Highlights")}</Link>
                </li>
                {/* <li>
                  <Link to="/locations">{props.t("Locations")}</Link>
                </li> */}
                    <li>
                        <Link to="/#" className="has-arrow waves-effect">
                          {/* <i className="fas fa-users"></i> */}
                          <span>{props.t("Locations")}</span>
                        </Link>
                        <ul className="sub-menu">
                        <li>
                            <Link to="/states">{props.t("States")}</Link>
                          </li>
                          <li>
                            <Link to="/counties">{props.t("Counties")}</Link>
                          </li>
                          <li>
                            <Link to="/locations">{props.t("Cities")}</Link>
                          </li>
                        </ul>
                      </li>
                <li>
                  <Link to="/zipcodes">{props.t("ZIP Codes")}</Link>
                </li>
                <li>
                  <Link to="/location-pages">{props.t("Location Pages")}</Link>
                </li>    
                <li>
                  <Link to="/zipcode-pages">{props.t("ZIP Code Pages")}</Link>
                </li> 
                <li>
                  <Link to="/team">{props.t("Team Members")}</Link>
                </li>
                <li>
                  <Link to="/terms-conditions">{props.t("Terms Conditions")}</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">{props.t("Privacy Policy")}</Link>
                </li>
                <li>
                  <Link to="/screening-process">{props.t("Screening Process")}</Link>
                </li>  
                <li>
                  <Link to="/code-of-ethics">{props.t("Code Of Ethics")}</Link>
                </li>  
                <li>
                  <Link to="/ministry">{props.t("Ministry")}</Link>
                </li> 
                <li>
                  <Link to="/pages">{props.t("Manage Page Meta")}</Link>
                </li>   
                <li>
                  <Link to="/here-from-us">{props.t("Here From Us")}</Link>
                </li>  
                <li>
                  <Link to="/url-redirects">{props.t("URL Redirects")}</Link>
                </li> 

              </ul>
            </li>
            <li>
              <Link to="/analytics" className=" waves-effect">
                <i className="fas fa-chart-line"></i>
                <span>{props.t("Analytics")}</span>
              </Link>
            </li>
            
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))