import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody, Button, Badge, Label, Modal, Spinner} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
  } from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import { get, del, put, post } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { HeaderFormatter } from "../../helpers/methods";

import { getAccessToken } from "../../helpers/jwt-token-access/accessToken";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"



const ReviewAnswers = (props) => {

    const { id } = props.match.params  
    const [answers, setAnswers] = useState([]);
    const [totalSize, setTotalSize] = useState(0);
    const [page, setpage] = useState(1)
    const [sizePerPage, setsizePerPage] = useState(20)
    const [SearchText, setsearchText] = useState("")
    const [selected_id, setSelected_id] = useState(null)
    const [success_confirm, setSuccess_confirm] = useState(false)
    const [alert_confirm, setAlert_confirm] = useState(false)
    const [dynamic_title, setDynamic_title] = useState("")
    const [dynamic_description, setDynamic_description] = useState("")
    const [loaddata, setLoadData] = useState(false)
    const [modal_standard, setmodal_standard] = useState(false)


  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }


    const columns = [
        {
        dataField: "id",
        text: "ID",
        sort: true
        },
        {
        dataField: "order_no",
        text: "Order",
        headerFormatter:HeaderFormatter,
        sort: true,
        headerStyle: { width: '5%'  },
        },
        {
        dataField: "title",
        text: "Title",
        headerFormatter:HeaderFormatter,
        },
        {
        dataField: "weight",
        text: "Weight",
        headerFormatter:HeaderFormatter,
        },
        {
        dataField: "isactive",
        text: "Active",
        headerFormatter:HeaderFormatter,
        formatter: (cellContent) => (
            cellContent === 1?
            <Badge className="bg-success btn-rounded p-2"> {"Active"} </Badge>
            :
            <Badge className="bg-danger btn-rounded p-2"> {"Not Active"} </Badge>
        ),
        },
        {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        headerFormatter:HeaderFormatter,
        headerStyle: { width: '10%'  },
        formatter: (cellContent, row) => (
            <React.Fragment>
            <Link to={"#"} onClick={() => EditSelectedRow(row)} className="me-3 text-primary"><i className="mdi mdi-pencil font-size-18"></i></Link>
            <Link to="#" onClick={() => {setAlert_confirm(true); setSelected_id(row)}} className="text-danger"><i className="mdi mdi-trash-can font-size-18"></i></Link>
            </React.Fragment>
        ),
        },
    ]

    useEffect(() => {
        loadData(`${BASE_URL}/api/tgcl/dashboard/answers/?question=${id}`, sizePerPage, page, SearchText)
        
    }, []);

    function EditSelectedRow(row){
        setSelected_id(row)
        setmodal_standard(true)
        
      }

    function loadData(url , sizePerPage, page , searchText){
        let data = get(url, { headers: { 'Content-Type': 'application/json' }} )
        data.then(response => {
        setAnswers(response.data.results)
        setTotalSize(response.data.count)
        setpage(page)
        setsizePerPage(sizePerPage)
        setsearchText(searchText)
        setmodal_standard(false)
        setLoadData(true)
        })
    }

    function NoDataIndication(){
      if (loaddata && answers.length === 0){
          return (
          
              <div className="d-flex align-items-center justify-content-center">
                  <h4 className="my-3">Table is Empty</h4>
              </div>
        )
  
      }else{
          return (
          
              <div className="d-flex align-items-center justify-content-center">
                  <Spinner className="my-3" color="dark" />
              </div>
        )
          
      } 
    }

    function handleQuestionClick (selected_id) { 
    
        del(`${BASE_URL}/api/tgcl/dashboard/answers/${selected_id}/`,   { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()}, validateStatus: false})
        .then(res => 
            {if(res.status >= 400){
                console.log("error")
            }
            else{
                let new_page = page
                if(answers.length < 2){
                new_page = new_page>1? new_page-1:1
                }
                let url = `${BASE_URL}/api/tgcl/dashboard/answers/?question=${id}&size=${sizePerPage}&page=${new_page}`
                url = SearchText && SearchText !== "" ? url+`&search=${SearchText}`: url
                loadData(url , sizePerPage, new_page , SearchText)
                setSuccess_confirm(true)
                setAlert_confirm(false)
                setDynamic_title('Deleted!')
                setDynamic_description("Answer Has been deleted.") 
                setSelected_id(null)
            }
        }
        )
    }
    
    function handleTableChange (type, { page, sizePerPage, searchText, }) {
        setTimeout(() => {
        let new_page = page
        let url = `${BASE_URL}/api/tgcl/dashboard/answers/?question=${id}&page_size=${sizePerPage}&&page=${new_page}`
        if (searchText && searchText !== ""){
            new_page = SearchText !== searchText? 1 : new_page
            url = `${BASE_URL}/api/tgcl/dashboard/answers/?question=${id}&page_size=${sizePerPage}&page=${new_page}&search=${searchText}`
        }
        window.scrollTo(0, 0)
        loadData(url , sizePerPage, new_page , searchText)
        }, 1000);
    }

    function handleSubmit(e, values){


        let data = {"title": values.title, "question":id, "weight":values.weight, "order_no": values.order_no, "isactive": document.getElementById('isactive').checked?1:0 }
        if(selected_id){
            put(`${BASE_URL}/api/tgcl/dashboard/answers/${selected_id.id}/`, data ,{ headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()}, validateStatus: false})
            .then(response => {
            if(response.status >= 400){
                console.log(response, "error")
                tog_standard()
            }else{
                let url = `${BASE_URL}/api/tgcl/dashboard/answers/?question=${id}&page_size=${sizePerPage}&&page=${page}`
                url = SearchText && SearchText !== "" ? url+`&search=${SearchText}`: url
                loadData(url , sizePerPage, page , SearchText)
                
            }
            },
            error => {
                tog_standard()
        
            })

        }else{

            post(`${BASE_URL}/api/tgcl/dashboard/answers/`, data , {  validateStatus: false } )
            .then(response => {
            if(response.status >= 400){
                console.log(response, "error")
                tog_standard()

            }else{
                loadData(`${BASE_URL}/api/tgcl/dashboard/answers/?question=${id}`, sizePerPage, page, SearchText)
                
            }
            },
            error => {
                tog_standard()

        })

        }
        
      }
    
    const defaultSorted = [{
        dataField: 'order_no',
        order: 'asc'
        }];

    const { SearchBar } = Search

        //pagination customization
    const pageOptions = {
        page: page,
        sizePerPage: sizePerPage,
        totalSize: totalSize, // replace later with size(Order),
        custom: true,
    }

    return (
        <React.Fragment>
        <div className="page-content">

            <Breadcrumbs title="Feedback Questions" breadcrumbItem='Feedback Answers' link="/feedback-questions" />
            <Row>
            <Col xs="12">
                <Card>
                <CardBody>
                    <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={answers}
                    >
                    {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                        keyField="id"
                        data={answers}
                        columns={columns}
                        remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                        bootstrap5
                        search
                        >
                        {toolkitProps => (
                            <React.Fragment>
                            
                            <Row className="mb-2">
                                <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                    <SearchBar
                                        {...toolkitProps.searchProps}
                                    />
                                    
                                    </div>
                                </div>
                                </Col>
                                <Col sm="8">
                                <div className="text-sm-end">
                                    <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded mb-2 me-2"
                                    onClick={()=> {tog_standard();setSelected_id(null)}}
                                    >
                                    <i className="mdi mdi-plus me-1" />{" "}
                                    Add New Answer
                                    </Button>
                                </div>
                                </Col>
                            </Row>
                            <div className="table-responsive">

                                <BootstrapTable
                                remote
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={true}
                                hover
                                defaultSorted={defaultSorted}
                                classes={
                                    "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                onTableChange={ handleTableChange }
                                noDataIndication = { NoDataIndication }
                                />
                                
                            </div>
                            <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                {...paginationProps}
                                />
                            </div>
                            </React.Fragment>
                        )}
                        </ToolkitProvider>
                    )}
                    </PaginationProvider>
                </CardBody>
                </Card>
            </Col>
            </Row>
            {alert_confirm ? (
                <SweetAlert
                title="Are you sure?"
                warning
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => handleQuestionClick(selected_id.id)}
                onCancel={() =>
                { setAlert_confirm(false) 
                    setSelected_id(null)
                }
                }
                >
                You won't be able to revert this!
                </SweetAlert>
            ) : null}

            {success_confirm ? (
                <SweetAlert
                success
                title={dynamic_title}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => 
                { setSuccess_confirm(false) 
                    setAlert_confirm(false)
                }
                }
                >
                    {dynamic_description}
                </SweetAlert>
                )
            : null
            }
            
        </div>
        <Col sm={6} md={4} xl={3}>
            <Modal
              isOpen={modal_standard}
              toggle={() => {
                tog_standard()
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">{`${selected_id?'Edit':'Add'} Feedback Answer`} </h5>     

                <button
                  type="button"
                  onClick={() => {
                    setmodal_standard(false)
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                <div className="modal-body">
                  <Row>
                      <Col md="12">
                          <div className="mb-3">
                          <Label htmlFor="title">Title</Label>
                          <AvField
                              name="title"
                              placeholder="Title"
                              type="text"
                              errorMessage="Enter Title"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="title"
                              value={selected_id?selected_id.title:""}
                          />
                          </div>
                      </Col>
                  </Row>
                  <Row>
                  <Col md="6">
                          <div className="mb-3">
                            <Label htmlFor="order">Weight</Label>
                            <AvField
                                name="weight"
                                placeholder="Weight"
                                type="text"
                                errorMessage="Please Enter Weight"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="weight"
                                value={selected_id?selected_id.weight:0}
                            />
                          </div>
                        </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Label htmlFor="order">Order</Label>
                          <AvField
                              name="order_no"
                              placeholder="Order"
                              type="number"
                              errorMessage=" Please provide a valid Order."
                              className="form-control"
                              validate={{ required: { value: true } }}
                              value={selected_id?selected_id.order_no:0}
                              id="order_no"
                          />
                        </div>
                      </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                        <div className="form-check mb-3">
                            <input className="form-check-input" type="checkbox" defaultChecked={selected_id?selected_id.isactive === 1?true:false:false} id="isactive"
                                required />{" "}
                            <label className="form-check-label" htmlFor="isactive">
                            Active</label>

                        </div>
                    </Col>
                  </Row>
                    
                </div>
                <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary waves-effect waves-light"
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => {
                    tog_standard()
                  }}
                  className="btn btn-primary waves-effect"
                  data-dismiss="modal"
                >
                  Close
                </button>
                
              </div>
              </AvForm>

            </Modal>
          </Col>

        </React.Fragment>
    )
}

export default ReviewAnswers
