import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap"
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { toast } from "react-toastify";
import 'toastr/build/toastr.min.css'
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken";

import { BASE_URL } from "../../helpers/url_helper";
import { get, } from "../../helpers/api_helper";

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


import {
    EditorState, convertToRaw, ContentState, left, right, center, justify, undo, redo, bold, italic, underline,
    ordered, indent, outdent, link, unlink, eraser, unordered
} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";


const AddContractor = (props) => {


    const queryParams = new URLSearchParams(props.location.search);
    const type = queryParams.get('type'); // This will extract 'endorseme

    console.log(type, "props")


    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [rawMessage, setrawMessage] = useState('');


    const [selectedGroup, setselectedGroup] = useState({ label: "Select Agent", value: null });
    const [selectedState, setselectedState] = useState({ label: "Select State", value: null });
    const [contracortype, setContracortype] = useState(type === "endorsenemt" ? { label: "Endorsement Partner", value: "ENDORSEMENT_PARTNER" } : { label: "Directory Partner", value: "DIRECTORY_PARTNER" });

    const [didMount, setDidMount] = useState(false);
    const [states, setStates] = useState([]);
    const [selectedCategory, setselectedCategory] = useState({ label: "Select Service", value: null })
    const [selectedSubCategories, setSelectedSubCategories] = useState([])
    const [categoryOptions, SetCategoryOptions] = useState([])
    const [subCategoryOptions, SetSubCategoryOptions] = useState([])
    const [phone, setPhone] = useState("")
    const [ppc_source, setppc_source] = useState("")
    const [agents, setAgents] = useState([]);
    const [logo, setLogo] = useState(null);
    const [img_1, setImg1] = useState(null);
    const [img_2, setImg2] = useState(null);
    const [img_3, setImg3] = useState(null);




    function onEditorStateChange(editorState) {
        setEditorState(editorState)
        setrawMessage(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    };


    const ContractorOptions = [
        {
            label: "Select Type",
            options: [
                { label: "Directory Partner", value: "DIRECTORY_PARTNER" },
                { label: "Endorsement Partner", value: "ENDORSEMENT_PARTNER" }
            ]
        }
    ];


    useEffect(() => {
        setDidMount(true);
        loadState()
        loadAgents()
        loadParentCategories()

        return () => setDidMount(false);

    }, []);



    function loadParentCategories() {
        get(`${BASE_URL}/api/tgcl/categories/?page_size=200`, { 'Content-Type': 'application/json' })
            .then(response => {
                let options = []
                let cat_data = response.data.results
                for (let i = 0; i < cat_data.length; i++) {

                    options.push({ label: cat_data[i].heading, value: cat_data[i].id_num })
                }

                SetCategoryOptions(options)
            },
                error => {
                    props.history.push('/contractors')
                })

    }

    function loasChildCategories(id) {
        get(`${BASE_URL}/api/tgcl/headings/?parent_id=${id}&page_size=50`, { 'Content-Type': 'application/json' })
            .then(response => {
                let options = []
                let cat_data = response.data.results
                for (let i = 0; i < cat_data.length; i++) {

                    options.push({ label: cat_data[i].heading, value: cat_data[i].id_num })
                }

                SetSubCategoryOptions(options)
                setSelectedSubCategories(options)
            },
                error => {
                    props.history.push('/contractors')

                })

    }

    function loadState() {
        let data = get(`${BASE_URL}/api/tgcl/state/?page_size=200`, { 'Content-Type': 'application/json' })
        data.then(response => {
            let options = []
            let state_data = response.data.results
            for (let i = 0; i < state_data.length; i++) {

                options.push({ label: state_data[i].state, value: state_data[i].id_num })
            }

            setStates(options)
        },
            error => {
                props.history.push('/contractors')

            })

    }

    function loadAgents() {
        get(`${BASE_URL}/api/accounts/users/`, { 'Content-Type': 'application/json' })
            .then(response => {
                let options = []
                let agents_data = response.data.results
                for (let i = 0; i < agents_data.length; i++) {

                    options.push({ label: agents_data[i].username, value: agents_data[i].id })
                }
                setAgents(options)
            },
                error => {
                    props.history.push('/contractors')

                })

    }

    if (!didMount) {
        return null;
    }

    function handleSelectGroup(selectedGroup) {
        setselectedGroup(selectedGroup);
    }

    function handleSelectState(selectedState) {
        setselectedState(selectedState);
    }


    const States = [
        {
            label: "States",
            options: states
        },
    ];

    const Agents = [
        {
            label: "Agents",
            options: agents
        },
    ];

    const Categories = [
        {
            label: "Categories",
            options: categoryOptions
        },
    ];

    function handleChange(e, setData) {
        setData(e.target.files[0]);
        // URL.createObjectURL
    }

    function addPhoneNumber(id) {
        let area_code = prompt("Please enter a three-digit area code:");
        if (area_code !== '' && area_code !== undefined) {
            get(`${BASE_URL}/api/tgcl/dashboard/add-phone-number/?area_code=${area_code}`, { 'Content-Type': 'application/json' })
                .then(response => {
                    if (response.status >= 400) {
                        console.log(response)
                        toast.error("Somthing went Wrong")

                    } else {
                        let num = response.data.friendly_name
                        num = num.replace("(", "")
                        num = num.replace(" ", "")
                        num = num.replace(")", "-")
                        if (id === "ppc") {
                            setppc_source(num)
                        } else {
                            setPhone(num)
                        }
                    }

                })
        }
        else {
            alert("Acquire canceled.");
        }

    }

    function releasePhoneNumber(id) {

        let number = phone
        if (id === "ppc") {
            number = ppc_source
        }
        if (number !== "") {
            number = number.replaceAll("-", "")
            number = number[0] === "+" ? number : `+1${number}`
            if (window.confirm("Are you sure you want to decomission this number?  This cannot be undone.")) {
                get(`${BASE_URL}/api/tgcl/dashboard/delete-phone-number/?phone_number=${number}`, { 'Content-Type': 'application/json' })
                    .then(response => {
                        if (response.status >= 400) {
                            console.log(response)
                            toast.error("Somthing went Wrong")
                        }
                    })
                if (id === "ppc") {
                    setppc_source("")
                } else {
                    setPhone("")
                }

            }

        }

    }


    function handleSubmit(e, values) {
        const formData = new FormData();
        if (logo) {
            formData.append('logo', logo, logo.name)
        }
        if (img_1) {
            formData.append('img_1', img_1, img_1.name)
        }
        if (img_2) {
            formData.append('img_2', img_2, img_2.name)
        }
        if (img_3) {
            formData.append('img_3', img_3, img_3.name)
        }
        if (selectedSubCategories.length === 0) {
            toast.error("Please Select Sub Categories")

        } else {
            for (let i = 0; i < selectedSubCategories.length; i++) {

                formData.append("sub_categories", selectedSubCategories[i].value)
            }

            if (contracortype.value) {
                formData.append("contractor_type", contracortype.value)
            }
            formData.append("state", selectedState.value)
            formData.append("agent", selectedGroup.value)
            formData.append("bus_name", values.bus_name)
            formData.append("address", values.address)
            formData.append("city", values.city)
            formData.append("zip", values.zip)
            formData.append("service", selectedCategory.value)
            formData.append("phone", values.phone)
            formData.append("ppc_source", values.ppc_source)
            formData.append("email", values.email)
            formData.append("email2", values.email2)
            formData.append("email3", values.email3)
            formData.append("contact_name", values.contact_name)
            formData.append("contact_phone", values.contact_phone)
            formData.append("contact_phone_2", values.contact_phone_2)
            formData.append("contact_email", values.contact_email)
            formData.append("url", values.url)
            formData.append("license", values.license)
            formData.append("isactive", document.getElementById('isactive').checked ? 1 : 0)
            formData.append("callemail", document.getElementById('callemail').checked ? 1 : 0)
            formData.append("approved", document.getElementById('approved').checked)
            formData.append("archive", document.getElementById('archive').checked)
            formData.append('description', rawMessage)

            const options = {
                method: 'POST',
                body: formData,
                headers: { 'Authorization': getAccessToken() }
            };
            fetch(`${BASE_URL}/api/tgcl/contractor/`, options)
                .then(res => {
                    if (res.status >= 400) {
                        res.json()
                            .then(
                                (data) => {
                                    console.log(data, "-error")
                                    if (data.zip) {
                                        toast.error("Ensure this field has no more than 5 characters.")
                                    } else {
                                        toast.error("Somthing Went Wrong.")
                                    }
                                }
                            )
                    }
                    else {
                        res.json()
                            .then(data => {
                                if (type) {
                                    props.history.push(`/contractor/${data.id}?type=${type}`)

                                } else {
                                    props.history.push(`/contractor/${data.id}`)
                                }
                            })

                    }
                }
                )
                .catch((err) => {
                    console.log(err)
                    toast.error("Somthing Went Wrong.")

                })
        }

    }

    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="Contractors" breadcrumbItem="Add Contractor" link="/contractors" />

                <Row>
                    <Col md="12">

                        <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                            <Row>
                                <Col sm="6">
                                    <Card>
                                        <CardBody>
                                            <CardTitle>Contractor Detail </CardTitle>

                                            <Row>
                                                <Col md="12">
                                                    <div className="mb-3">
                                                        <Label htmlFor="bus_name">Business Name</Label>
                                                        <AvField
                                                            name="bus_name"
                                                            type="text"
                                                            errorMessage="Enter Business Name"
                                                            className="form-control"
                                                            validate={{ required: { value: true } }}
                                                            id="bus_name"

                                                        />
                                                    </div>
                                                </Col>

                                            </Row>
                                            {/* <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                        <Label htmlFor="slug">Slug</Label>
                                                        <AvField
                                                            name="slug"
                                                            placeholder="Slug"
                                                            type="text"
                                                            errorMessage="Enter Slug"
                                                            className="form-control"
                                                            value={slug}
                                                            validate={{ required: { value: true } }}
                                                            id="slug"
                                                            onChange={(e)=> setSlug(slugify(e.target.value))}
                                                        />
                                                        </div>
                                                    </Col>
                                                
                                                </Row> */}

                                            <Row>
                                                <Col md="12">
                                                    <div className="mb-3">
                                                        <Label htmlFor="address">Address</Label>
                                                        <AvField
                                                            name="address"
                                                            type="text"
                                                            errorMessage="Enter Address"
                                                            className="form-control"
                                                            id="address"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <div className="mb-3">
                                                        <AvField
                                                            className="mb-3"
                                                            name="email"
                                                            label="E-Mail"
                                                            type="email"
                                                            errorMessage="Invalid Email"
                                                            validate={{
                                                                email: { value: true },
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="mb-3">
                                                        <Label>State</Label>
                                                        <Select
                                                            value={selectedState}
                                                            onChange={(e) => {
                                                                handleSelectState(e);
                                                            }}
                                                            options={States}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <div className="mb-3">
                                                        <Label htmlFor="city">City</Label>
                                                        <AvField
                                                            name="city"
                                                            type="text"
                                                            errorMessage=" Please provide a valid city."
                                                            className="form-control"
                                                            id="city"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="mb-3">
                                                        <Label htmlFor="zip">Zip</Label>
                                                        <AvField
                                                            name="zip"
                                                            type="text"
                                                            errorMessage=" Please provide a valid zip."
                                                            className="form-control"
                                                            id="zip"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            {contracortype.value !== "ENDORSEMENT_PARTNER" ?
                                                (
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <AvField
                                                                    name="phone"
                                                                    id="phone"
                                                                    label="Tracking Phone Number 1"
                                                                    type="text"
                                                                    errorMessage="Enter Only Digits"
                                                                    validate={{
                                                                        pattern: {
                                                                            value: "^[0-9+-]+$",
                                                                            errorMessage: "Invalid Contact"
                                                                        },
                                                                    }}
                                                                    value={phone}
                                                                    onChange={(e) => setPhone(e.target.value)}
                                                                />
                                                                <Link to={'#'} onClick={() => addPhoneNumber('phone')} className="text-primary" style={{ fontSize: '10px' }}>Acquire New Number</Link> | <Link to={'#'} style={{ fontSize: '10px' }} onClick={() => releasePhoneNumber('phone')} className="text-primary">Release Number</Link>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="mb-3">
                                                                <AvField

                                                                    name="ppc_source"
                                                                    label="Tracking Phone Number 2"
                                                                    type="text"
                                                                    errorMessage="Enter Only Digits"
                                                                    validate={{
                                                                        pattern: {
                                                                            value: "^[0-9+-]+$",
                                                                            errorMessage: "Invalid Contact"
                                                                        },
                                                                    }}
                                                                    value={ppc_source}
                                                                    onChange={(e) => setppc_source(e.target.value)}
                                                                />
                                                                <Link to={'#'} onClick={() => addPhoneNumber('ppc')} className="text-primary" style={{ fontSize: '10px' }}>Acquire New Number</Link> | <Link to={'#'} style={{ fontSize: '10px' }} onClick={() => releasePhoneNumber('ppc')} className="text-primary">Release Number</Link>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ) : null}


                                            <Row>
                                                <Col md="6">
                                                    <div className="mb-3">
                                                        <Label htmlFor="contact_name">Contact Name</Label>
                                                        <AvField
                                                            name="contact_name"
                                                            type="text"
                                                            errorMessage="Enter Contact Name"
                                                            className="form-control"
                                                            id="contact_name"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="mb-3">
                                                        <AvField
                                                            className="mb-3"
                                                            name="contact_email"
                                                            label="Contact E-Mail"
                                                            type="email"
                                                            errorMessage="Invalid Email"
                                                            validate={{
                                                                email: { value: true },
                                                            }}
                                                        />
                                                    </div>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <div className="mb-3">
                                                        <AvField
                                                            className="mb-3"
                                                            name="email2"
                                                            label="Additional E-Mail 1"
                                                            type="email"
                                                            errorMessage="Invalid Email"
                                                            validate={{
                                                                // required: { value: true },
                                                                email: { value: true },
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="mb-3">
                                                        <AvField
                                                            className="mb-3"
                                                            name="email3"
                                                            label="Additional E-Mail 2"
                                                            type="email"
                                                            errorMessage="Invalid Email"
                                                            validate={{
                                                                // required: { value: true },
                                                                email: { value: true },
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="contact_phone"
                                                            label="Contact Phone 1"
                                                            type="text"
                                                            errorMessage="Enter Only Digits"
                                                            validate={{
                                                                pattern: {
                                                                    value: "^[0-9+-]+$",
                                                                    errorMessage: "Invalid Contact"
                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3">
                                                        <AvField
                                                            name="contact_phone_2"
                                                            label="Contact Phone 2"
                                                            type="text"
                                                            errorMessage="Enter Only Digits"
                                                            validate={{
                                                                pattern: {
                                                                    value: "^[0-9+-]+$",
                                                                    errorMessage: "Invalid Contact"
                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/* <Row>
                                                        <Col md="12">
                                                            <div className="mb-3">
                                                            <Label htmlFor="username">Username</Label>
                                                            <AvField
                                                                name="user_name"
                                                                placeholder="Username"
                                                                type="text"
                                                                errorMessage="Enter Username"
                                                                className="form-control"
                                                                // validate={{ required: { value: true } }}
                                                                id="username"
                                                            />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm="6">
                                                            <Label>Password</Label>
                                                            <AvField
                                                            name="passwrd"
                                                            type="password"
                                                            placeholder="Password"
                                                            errorMessage="Enter password"
                                                            // validate={{ required: { value: true } }}
                                                            />
                                                        </Col>
                                                        <Col sm="6">
                                                            <Label>Re-type Password</Label>
                                                            <AvField
                                                                name="password1"
                                                                type="password"
                                                                placeholder="Re-type Password"
                                                                errorMessage="Enter Re-password"
                                                                value={contractor.passwrd}
                                                                validate={{
                                                                    // required: { value: true },
                                                                    match: { value: "passwrd" },
                                                                }}
                                                                />
                                                        </Col>
                                                    
                                                    </Row> */}
                                            {/* Remove URL to category  */}

                                            {/* <Row >
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="trucks">How many trucks/crews does your company have?</Label>
                                                            <AvField
                                                                name="trucks"
                                                                placeholder="Number of Trucks/Crews"
                                                                type="text"
                                                                className="form-control"
                                                                id="trucks"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="established" >Year Business Established</Label>
                                                            <AvField
                                                                name="established"
                                                                placeholder="Year Business Established"
                                                                type="text"
                                                                className="form-control"
                                                                id="established"
                                                                />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="exp">Years of Experience in Your Field</Label>
                                        
                                                            <AvField
                                                                name="exp"
                                                                placeholder="Years of Experience in Your Field"
                                                                type="text"
                                                                className="form-control"
                                                                id="exp"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row> */}


                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm="6">
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col md="12">
                                                    <div className="mb-3">
                                                        <Label htmlFor="url">URL</Label>
                                                        <AvField
                                                            name="url"
                                                            type="text"
                                                            errorMessage="Enter URL"
                                                            className="form-control"
                                                            // validate={{ required: { value: true } }}
                                                            id="url"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <div className="mb-3">
                                                        <Label htmlFor="license">License</Label>
                                                        <AvField
                                                            name="license"
                                                            type="text"
                                                            errorMessage="Enter License"
                                                            className="form-control"
                                                            // validate={{ required: { value: true } }}
                                                            id="license"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="mb-3">
                                                        <Label>Agent</Label>
                                                        <Select
                                                            value={selectedGroup}
                                                            onChange={(e) => {
                                                                handleSelectGroup(e);
                                                            }}
                                                            options={Agents}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                </Col>



                                                <Col md="12">

                                                    <div className="mb-3">
                                                        <Label htmlFor="service" >Type of Contractor</Label>

                                                        <Select
                                                            value={contracortype}
                                                            onChange={(e) => {
                                                                setContracortype(e)

                                                            }}
                                                            options={ContractorOptions}
                                                            classNamePrefix="select2-selection"
                                                        // isDisabled = {true}
                                                        />

                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row>
                                                <Col md="12">

                                                    <div className="mb-3">
                                                        <Label htmlFor="service" >Field of service such as Air Conditioning, Plumbing, etc</Label>

                                                        <Select
                                                            value={selectedCategory}
                                                            onChange={(e) => {
                                                                setselectedCategory(e)
                                                                setSelectedSubCategories([])
                                                                loasChildCategories(e.value)
                                                                SetSubCategoryOptions([])
                                                            }}
                                                            options={Categories}
                                                            classNamePrefix="select2-selection"
                                                        />

                                                    </div>
                                                </Col>

                                            </Row>
                                            <Row>



                                                <Col md="12">

                                                    <div className="mb-3">
                                                        <Label htmlFor="service" >Sub Categories</Label>

                                                        <Select
                                                            value={selectedSubCategories}
                                                            onChange={(e) => {
                                                                setSelectedSubCategories(e)
                                                            }}
                                                            isMulti={true}
                                                            options={subCategoryOptions}
                                                            classNamePrefix="select2-selection"
                                                        />

                                                    </div>
                                                </Col>


                                                {(contracortype.value !== "ENDORSEMENT_PARTNER") ? (null) : (
                                                    <Col md="12" className="mb-3">
                                                        <Label >Description / Narrative</Label>
                                                        <Editor

                                                            toolbar={{
                                                                options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'remove', 'history'],
                                                                inline: {
                                                                    inDropdown: false,
                                                                    className: undefined,
                                                                    component: undefined,
                                                                    dropdownClassName: undefined,
                                                                    options: ['bold', 'italic', 'underline'],
                                                                    bold: { icon: bold, className: undefined },
                                                                    italic: { icon: italic, className: undefined },
                                                                    underline: { icon: underline, className: undefined },
                                                                },
                                                                blockType: {
                                                                    inDropdown: true,
                                                                    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                                                                    className: undefined,
                                                                    component: undefined,
                                                                    dropdownClassName: undefined,
                                                                },

                                                                list: {
                                                                    inDropdown: false,
                                                                    className: undefined,
                                                                    component: undefined,
                                                                    dropdownClassName: undefined,
                                                                    options: ['unordered', 'ordered', 'indent', 'outdent'],
                                                                    unordered: { icon: unordered, className: undefined },
                                                                    ordered: { icon: ordered, className: undefined },
                                                                    indent: { icon: indent, className: undefined },
                                                                    outdent: { icon: outdent, className: undefined },
                                                                },
                                                                textAlign: {
                                                                    inDropdown: false,
                                                                    className: undefined,
                                                                    component: undefined,
                                                                    dropdownClassName: undefined,
                                                                    options: ['left', 'center', 'right', 'justify'],
                                                                    left: { icon: left, className: undefined },
                                                                    center: { icon: center, className: undefined },
                                                                    right: { icon: right, className: undefined },
                                                                    justify: { icon: justify, className: undefined },
                                                                },

                                                                link: {
                                                                    inDropdown: false,
                                                                    className: undefined,
                                                                    component: undefined,
                                                                    popupClassName: undefined,
                                                                    dropdownClassName: undefined,
                                                                    showOpenOptionOnHover: true,
                                                                    defaultTargetOption: '_self',
                                                                    options: ['link', 'unlink'],
                                                                    link: { icon: link, className: undefined },
                                                                    unlink: { icon: unlink, className: undefined },
                                                                    linkCallback: undefined
                                                                },
                                                                remove: { icon: eraser, className: undefined, component: undefined },
                                                                history: {
                                                                    inDropdown: false,
                                                                    className: undefined,
                                                                    component: undefined,
                                                                    dropdownClassName: undefined,
                                                                    options: ['undo', 'redo'],
                                                                    undo: { icon: undo, className: undefined },
                                                                    redo: { icon: redo, className: undefined },
                                                                },
                                                            }}
                                                            editorState={editorState}
                                                            toolbarClassName="toolbarClassName"
                                                            wrapperClassName="wrapperClassName"
                                                            editorClassName="editorClassName"
                                                            onEditorStateChange={onEditorStateChange}
                                                        />
                                                    </Col>)}




                                            </Row>

                                            {/* <Row >
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="url" >Accredited Since in BBB Business</Label>
                                        
                                                            <AvField
                                                                name="Accredited Since"
                                                                placeholder="Since"
                                                                type="date"
                                                                className="form-control"
                                                                id="since"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                
                                                <Row >
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="bbb_url" >Better Business Bureau URL</Label>
                            
                                                            <AvField
                                                                name="bbb_url"
                                                                placeholder="Better Business Bureau URL"
                                                                type="text"
                                                                className="form-control"
                                                                id="bbb_url"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="bbb" >Better Business Bureau Standing</Label>
                            
                                                            <AvField
                                                                name="bbb"
                                                                placeholder="Better Business Bureau Standing"
                                                                type="text"
                                                                className="form-control"
                                                                id="bbb"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="genlib">Amount of General Liability Carried</Label>
                                                        
                                                            <AvField
                                                                name="genlib"
                                                                placeholder="Amount of General Liability Carried"
                                                                type="text"
                                                                className="form-control"
                                                                id="genlib"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row >
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="genlib">Do you offer a written 100% Satisfaction Guarantee to your clients?:</Label>
                                                    
                                                            <Select
                                                                // className="form-control"
                                                                value={guarantee}
                                                                onChange={(e) => {setGuarantee(e)}}
                                                                options={YesNoNAOptions}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>    
                                                </Row>
                                                <Row >
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="genlib" >Can you provide at least 3 industry professionals that can vouch for your long-term integrity and quality?:</Label>
                                                    
                                                            <Select
                                                                // className="form-control"
                                                                value={refs}
                                                                onChange={(e) => {
                                                                    setRefs(e)
                                                                }}
                                                                options={YesNoOptions}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row >
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="genlib" >Are you willing to undergo background investigations?:</Label>
                                                    
                                                            <Select
                                                                // className="form-control"
                                                                value={bgcheck}
                                                                onChange={(e) => {
                                                                    setBgcheck(e)
                                                                }}
                                                                options={YesNoOptions}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="genlib" >How did you hear of us?</Label>
                                                    
                                                            <Select
                                                                // className="form-control"
                                                                value={hear_from}
                                                                onChange={(e) => {
                                                                    setHear_from(e)
                                                                }}
                                                                options={HereFromOptions}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="genlib" >Are your technicians paid a commissions</Label>
                                                        
                                                            <Select
                                                                // className="form-control"
                                                                value={pay_comm}
                                                                onChange={(e) => {
                                                                    setPay_comm(e)
                                                                }}
                                                                options={YesNoNAOptions}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="no_pressure" >If YES, how do you insure your technicians are not using fear tactics or high pressure to increase their paycheck</Label>
                                                            <AvField
                                                                className="mb-3"
                                                                type="textarea"
                                                                rows="3"
                                                                name="no_pressure"
                                                                id="no_pressure"
                                                                placeholder="If YES, how do you insure your technicians are not using fear tactics or high pressure to increase their paycheck"
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="areas_served" >What area(s) do you serve?</Label>
                                                            <AvField
                                                                className="mb-3"
                                                                type="textarea"
                                                                rows="3"
                                                                name="areas_served"
                                                                id="areas_served"
                                                                placeholder="What area(s) do you serve?"
                                                            />

                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="mb-3">
                                                            <Label htmlFor="differences" >List any details separating your business from your competition?</Label>
                                                            <AvField
                                                                className="mb-3"
                                                                type="textarea"
                                                                rows="3"
                                                                name="differences"
                                                                id="differences"
                                                                placeholder="List any details separating your business from your competition?"
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row> */}
                                            <Row className="mt-3">
                                                <Col md="3">
                                                    <div className="form-check mb-3">
                                                        <input className="form-check-input" type="checkbox" defaultChecked={true} id="isactive"
                                                            required />{" "}
                                                        <label className="form-check-label" htmlFor="isactive">
                                                            Active</label>
                                                    </div>
                                                </Col>
                                                <Col md="3">
                                                    <div className="form-check mb-3">
                                                        <input className="form-check-input" type="checkbox" defaultChecked={true} id="approved"
                                                            required />{" "}
                                                        <label className="form-check-label" htmlFor="approved">
                                                            Approved</label>
                                                    </div>
                                                </Col>
                                                <Col md="3">
                                                    <div className="form-check mb-3">
                                                        <input className="form-check-input" type="checkbox" defaultChecked={false} id="callemail"
                                                            required />{" "}
                                                        <label className="form-check-label" htmlFor="callemail">
                                                            Call Notification</label>
                                                    </div>
                                                </Col>
                                                <Col md="3">
                                                    <div className="form-check mb-3">
                                                        <input className="form-check-input" type="checkbox" defaultChecked={false} id="archive"
                                                            required />{" "}
                                                        <label className="form-check-label" htmlFor="archive">
                                                            Archived</label>
                                                    </div>
                                                </Col>
                                            </Row>



                                            <Row>
                                                <Label htmlFor="icon">Logo</Label>
                                                <Col md="6">
                                                    <input id="logo" className="mb-3" type="file" onChange={(e) => handleChange(e, setLogo)} />
                                                </Col>
                                                <Col md="6">
                                                    {logo ?
                                                        <img src={URL.createObjectURL(logo)} alt="" style={{ width: "40%" }} className="m-3" />
                                                        : null}
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Label htmlFor="icon">Image 1</Label>
                                                <Col md="6">
                                                    <input id="img_1" className="mb-3" type="file" onChange={(e) => handleChange(e, setImg1)} />
                                                </Col>
                                                <Col md="6">
                                                    {img_1 ?
                                                        <img src={URL.createObjectURL(img_1)} alt="" style={{ width: "40%" }} className="m-3" />
                                                        : null}
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Label htmlFor="icon">Image 2</Label>
                                                <Col md="6">
                                                    <input id="img_2" className="mb-3" type="file" onChange={(e) => handleChange(e, setImg2)} />
                                                </Col>
                                                <Col md="6">
                                                    {img_2 ?
                                                        <img src={URL.createObjectURL(img_2)} alt="" style={{ width: "40%" }} className="m-3" />
                                                        : null}
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Label htmlFor="icon">Image 3</Label>
                                                <Col md="6">
                                                    <input id="img_3" className="mb-3" type="file" onChange={(e) => handleChange(e, setImg3)} />
                                                </Col>
                                                <Col md="6">
                                                    {img_3 ?
                                                        <img src={URL.createObjectURL(img_3)} alt="" style={{ width: "40%" }} className="m-3" />
                                                        : null}
                                                </Col>

                                            </Row>

                                            <Button color="primary" type="submit">
                                                Submit
                                            </Button>


                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                        </AvForm>

                    </Col>

                </Row>
            </div>

        </React.Fragment>

    )
}

export default AddContractor
