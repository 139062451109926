import React, { useState, useEffect } from "react"

import { Row, Col, Card, CardBody, Button, Label, Modal, Spinner } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom"
// Editable
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone,
  } from "react-bootstrap-table2-paginator"
  import ToolkitProvider from "react-bootstrap-table2-toolkit"
import Select from "react-select";
import { toast } from 'react-toastify';
import { AvForm, AvField } from "availity-reactstrap-validation"
import { get, del, post } from "../../helpers/api_helper"
import { BASE_URL } from "../../helpers/url_helper";
import { HeaderFormatter } from "../../helpers/methods";
import moment from "moment-timezone";

import { getAccessToken } from "../../helpers/jwt-token-access/accessToken";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const optionGroup = [
  {
    label: "Email Type",
    options: [
      // { label: "Contact Form", value: "Contact Form" },
      { label: "Contact Us", value: "Contact Us" },
      { label: "Email to Contractor", value: "Email to Contractor" },
      { label: "Initial Screening", value: "Initial Screening" },
      // { label: "Project Request", value: "Project Request" },
      { label: "Refer a Contractor Request", value: "Refer a Contractor Request" },
      { label: "Feedback Posted", value: "Review Posted" },
    ],
  },
];

const EmailForms = (props) => {

  const [emailForms, setEmailForms] = useState([]);
  const [totalSize, settotalSize] = useState(0);
  const [page, setpage] = useState(1)
  const [sizePerPage, setsizePerPage] = useState(20)
  const [selected_id, setSelected_id] = useState(null)
  const [success_confirm, setSuccess_confirm] = useState(false)
  const [alert_confirm, setAlert_confirm] = useState(false)
  const [dynamic_title, setDynamic_title] = useState("")
  const [dynamic_description, setDynamic_description] = useState("")
  const [emailType, setEmailType] = useState({ label: "Select Email Type", value: null });
  const [fromDate, setFromDate] = useState(moment(new Date()).subtract(7, 'd').tz("America/Chicago").format("YYYY-MM-DD"))
  const [toDate, setToDate] = useState(moment(new Date()).tz("America/Chicago").format("YYYY-MM-DD"))
  const [modal_standard, setmodal_standard] = useState(false)
  const [filter_option, setFilterOptions] = useState("")
  const [search_text, setsearchText] = useState("")
  const [loaddata, setLoadData] = useState(false)
  
  
  function tog_standard() {
    setmodal_standard(!modal_standard)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true
    },
    {
      dataField: "email_type",
      text: "Email Type",
      sort: true,
      headerFormatter:HeaderFormatter,
      headerStyle: { width: '20%'  },
      formatter: (cellContent, row) => (
        <Link to={`#`} onClick={()=> {setSelected_id(row); tog_standard()}} className="me-3 text-dark">{(cellContent).substring(0,30)}</Link>     
      ),
      title: (cell) => `${cell}`
    },
    {
      dataField: "email_from",
      text: "Email From",
      headerFormatter:HeaderFormatter,
      sort: true,
      headerStyle: { width: '20%'  },
      formatter: (cellContent, row) => (
        (cellContent).substring(0,30)    
      ),
      title: (cell) => `${cell}`
    },
    {
      dataField: "email_to",
      text: "Email To",
      headerFormatter:HeaderFormatter,
      sort: true,
      headerStyle: { width: '20%'  },
      formatter: (cellContent, row) => (
        (cellContent).substring(0,30)    
      ),
      title: (cell) => `${cell}`
    },
    {
      dataField: "email_cc",
      text: "Email Cc",
      headerFormatter:HeaderFormatter,
      headerStyle: { width: '20%'  },
      formatter: (cellContent, row) => (
        (cellContent).substring(0,30)    
      ),
      title: (cell) => `${cell}`
    },
    {
      dataField: "created",
      text: "Created",
      headerFormatter:HeaderFormatter,
      formatter: (cellContent) => (
      
        <React.Fragment>
          <p>{moment(cellContent).tz("America/Chicago").format('MM-DD-YYYY hh:mm A')}</p>        
        </React.Fragment>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      headerFormatter:HeaderFormatter,
      headerStyle: { width: '5%'  },
      formatter: (cellContent, row) => (
        <React.Fragment>
          <Link to="#" onClick={()=> {setSelected_id(row); tog_standard()}} className="text-primary"><i className="mdi mdi-pencil me-2 font-size-18"></i></Link>
          <Link to="#" onClick={() => {setAlert_confirm(true); setSelected_id(row)}} className="text-danger"><i className="mdi mdi-trash-can font-size-18"></i></Link>
        </React.Fragment>
      ),
    },
  ]

  useEffect(() => {
    loadData(`${BASE_URL}/api/tgcl/emailform/?page_size=${sizePerPage}`, sizePerPage, page, search_text)
    
  }, []);

  function loadData(url , sizePerPage, page , searchText){
    
    let data = get(url, { headers: { 'Content-Type': 'application/json' }} )
    data.then(response => {
    console.log(response.data,"response.data")

      setEmailForms(response.data.results)
      settotalSize(response.data.count)
      setpage(page)
      setsizePerPage(sizePerPage)
      setsearchText(searchText)
      setLoadData(true)
    })
  }

  if(modal_standard){
    setTimeout(() => {
      try{
        document.getElementsByTagName("table")[1].className="table table-bordered table-sm"
      }catch{
        console.log("-error")
      }
      
    }, 300);
  }

  function NoDataIndication(){
    if (loaddata && emailForms.length === 0){
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <h4 className="my-3">Table is Empty</h4>
            </div>
      )

    }else{
        return (
        
            <div className="d-flex align-items-center justify-content-center">
                <Spinner className="my-3" color="dark" />
            </div>
      )
        
    } 
  }


  function handleFormClicks (row) {     
    del(`${BASE_URL}/api/tgcl/emailform/${row.id}`, { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()}, validateStatus: false})
    .then(res => 
        {if(res.status >= 400){
          console.log(res, "error")
        }
        else{
          let new_page = page
          if(emailForms.length < 2){
            new_page = new_page>1? new_page-1:1
          }
          let url = `${BASE_URL}/api/tgcl/emailform/?page_size=${sizePerPage}&page=${new_page}`
          url = search_text && search_text !== "" ? url+`&search=${search_text}`: url
          url = filter_option && filter_option !== ""? url+filter_option : url
          loadData(url , sizePerPage, new_page , search_text)
          setSuccess_confirm(true)
          setAlert_confirm(false)
          setDynamic_title('Deleted!')
          setDynamic_description("Email Form Has been deleted.") 
          setSelected_id(null)
        }
      }
    )
  }

  function ClearFilter(){
    setToDate(moment(new Date()).tz("America/Chicago").format("YYYY-MM-DD"))
    setFromDate(moment(new Date()).subtract(7,'d').tz("America/Chicago").format("YYYY-MM-DD"))
    setEmailType({ label: "Select Email Type", value: null })
    loadData(`${BASE_URL}/api/tgcl/emailform/`, sizePerPage, page, search_text)
  }

  function FilterData(){
    let filter = "" 
    if(!emailType.value){
      toast.warning("Plase Select Email Type")
    }else{
      if(new Date(toDate)< new Date(fromDate)){
        toast.warning("To Date can not be less than from Date")
      }else{
        filter =`&email_type=${emailType.value}&start_date=${fromDate}&end_date=${toDate}`
        let url =`${BASE_URL}/api/tgcl/emailform/?page_size=${sizePerPage}&page=${1}${filter}`
  
        loadData(url , sizePerPage, 1 , "")
  
        setFilterOptions(filter)
  
      }

    }

  }

  
  function handleTableChange (type, { page, sizePerPage, searchText, }) {
    setTimeout(() => {
      let new_page = page
      let url = `${BASE_URL}/api/tgcl/emailform/?page_size=${sizePerPage}&&page=${new_page}`


      if (filter_option !== ""){
        url = `${BASE_URL}/api/tgcl/emailform/?page_size=${sizePerPage}&page=${new_page}${filter_option}`
      }
      
      if (searchText && searchText !== ""){
        if(filter_option !== ""){
          new_page = search_text !== searchText? 1 : new_page
          url = `${BASE_URL}/api/tgcl/emailform/?page_size=${sizePerPage}&page=${new_page}&search=${searchText}${filter_option}`

        }else{
          new_page = search_text !== searchText? 1 : new_page
          url = `${BASE_URL}/api/tgcl/emailform/?page_size=${sizePerPage}&page=${new_page}&search=${searchText}`
        }
        
      }
      window.scrollTo(0, 0)
      loadData(url , sizePerPage, new_page , searchText)
    }, 1000);
  }

  function handleSelectGroup(emailType) {
    setEmailType(emailType);
}
 
  const defaultSorted = [{
      dataField: 'name',
      order: 'desc'
    }];

  
    function handleReSendEmail(e, values){
      let message = encodeURIComponent(selected_id.email_content)
      let cc = values.email_cc
      let bcc = values.email_bcc
      let email_to = values.email_to
      let data = {"subject":values.email_subject, "email":email_to, "cc":cc, "bcc":bcc, "data":message}
      post(`${BASE_URL}/api/tgcl/send-email-form/`, data, { validateStatus: false})
      .then((resp) => {
        if(resp.status >= 400){

        }else{
          tog_standard()
        }
      })
    }


    //pagination customization
  const pageOptions = {
      page: page,
      sizePerPage: sizePerPage,
      totalSize: totalSize, // replace later with size(Order),
      custom: true,
      sizePerPageList: [ {
        text: '20', value: 20
      }, {
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }, {
        text: '200', value: 200
      } ]
  }

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem='Email Forms' link="/dashboard" />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  data={emailForms}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={emailForms}
                      columns={columns}
                      remote={{ filter: true, pagination: true, sort: true, cellEdit: true }}
                      bootstrap5
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <AvForm className="needs-validation" onValidSubmit={FilterData}>
                            <Row className="mb-2">
                              <Col sm="3">
                                <div className="text-sm-start mb-2">
                                    <Select
                                    value={emailType}
                                    onChange={(e) => {
                                        handleSelectGroup(e);
                                    }}
                                    options={optionGroup}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                                
                              </Col>
                              <Col sm="9">
                                <Row>
                                  <Col sm="6">
                                    <Row>
                                      <Col sm="6">
                                        <div className="text-sm-end mb-2">
                                          <AvField
                                            name="from_date"
                                            placeholder="Received From"
                                            type="date"
                                            errorMessage="From Date"
                                            className="form-control"
                                            value={fromDate}
                                            onChange={(e)=> setFromDate(e.target.value)}
                                            id="from_date"
                                          />
                                        </div>
                                      
                                      </Col>
                                      <Col sm="6">
                                        <div className="text-sm-end mb-2">
                                        <AvField
                                            name="to_date"
                                            placeholder="Category"
                                            type="date"
                                            errorMessage="To Date"
                                            className="form-control"
                                            value={toDate}
                                            onChange={(e)=> setToDate(e.target.value)}
                                            id="to_date"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col sm="6">
                                    <Row>
                                      <Col sm="12" className="mb-2">
                                        
                                        <Button type="submit" color="success" className="btn-rounded me-2" >
                                          Filter
                                        </Button>
                                        {" "}
                                        <Button type="button" color="primary" onClick={()=>ClearFilter()} className="btn-rounded me-2" >
                                          Clear
                                        </Button>
                                      </Col>
                                    </Row>
                                    
                                  </Col>
                                </Row>  
                              </Col>
                              
                            </Row>
                          </AvForm>
                          
                          
                          <div className="table-responsive">

                            <BootstrapTable
                              remote
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              responsive
                              bordered={false}
                              striped={true}
                              hover
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              onTableChange={ handleTableChange }
                              noDataIndication={ NoDataIndication }
                            />
                            
                          </div>
                          <Row>
                              
                            <Col sm={6}>
                              <div className="mb-3">
                                <SizePerPageDropdownStandalone
                                          { ...paginationProps }
                                        />
                              </div>
                              <div className="pagination pagination-rounded mb-2">
                                <PaginationTotalStandalone
                                  {...paginationProps}
                                />
                              </div>
                              
                            </Col>
                            <Col sm={6}>
                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                              
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {alert_confirm ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => handleFormClicks(selected_id)}
            onCancel={() =>
              { setAlert_confirm(false) 
                setSelected_id(null)
              }
            }
            >
            You won't be able to revert this!
          </SweetAlert>
          ) : null}

          {success_confirm ? (
            <SweetAlert
              success
              title={dynamic_title}
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => 
                { setSuccess_confirm(false) 
                  setAlert_confirm(false)
                }
              }
              >
                  {dynamic_description}
            </SweetAlert>
            )
            : null
          }
          <Col sm={6} md={4} xl={3}>
            <Modal
              isOpen={modal_standard}
              toggle={() => {
                tog_standard()
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  {selected_id?.email_type}
                    </h5>
                <button
                  type="button"
                  onClick={() => {
                    setmodal_standard(false)
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <AvForm className="needs-validation" onValidSubmit={handleReSendEmail}>
              <div className="modal-body">
                
                  <Row className="mb-3">
                      <Label htmlFor="email_subject" className="col-md-3 col-form-label">Email Subject:</Label>
                      <div className="col-md-9">
                          <AvField
                              name="email_subject"
                              placeholder="Email Subject"
                              type="text"
                              errorMessage="Enter Email Subject"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="email_subject"
                              value={selected_id?.email_subject}
                          />
                      </div>
                  </Row>
                  <Row className="mb-3">
                      <Label htmlFor="email_to" className="col-md-3 col-form-label">Email To:</Label>
                      <div className="col-md-9">
                          <AvField
                              name="email_to"
                              placeholder="Email To"
                              type="text"
                              errorMessage="Enter Email To"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="email_to"
                              value={selected_id?.email_to}
                          />
                      </div>
                  </Row>
                  <Row className="mb-3">
                      <Label htmlFor="email_cc" className="col-md-3 col-form-label">Email Cc:</Label>
                      <div className="col-md-9">
                          <AvField
                              name="email_cc"
                              placeholder="Email Cc"
                              type="text"
                              className="form-control"
                              id="email_cc"
                              value={selected_id?.email_cc}
                          />
                      </div>
                  </Row>
                  <Row className="mb-3">
                      <Label htmlFor="email_bcc" className="col-md-3 col-form-label">Email Bcc:</Label>
                      <div className="col-md-9">
                          <AvField
                              name="email_bcc"
                              placeholder="Email Bcc"
                              type="text"
                              className="form-control"
                              id="email_bcc"
                              value={selected_id?.email_bcc}
                          />
                      </div>
                  </Row>
                  <Row className="mb-3">
                      <Label htmlFor="email_from" className="col-md-3 col-form-label">Email From:</Label>
                      <div className="col-md-9">
                          <AvField
                              name="email_from"
                              placeholder="Email From"
                              type="text"
                              errorMessage="Enter Email From"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="email_from"
                              value={selected_id?.email_from}
                          />
                      </div>
                  </Row>
                  <Row className="mb-3">

                  </Row>
                    <Col className="col-sm-12">
                      <Label htmlFor="email_from" className="col-md-3 col-form-label">Email Content:</Label>
                      <div dangerouslySetInnerHTML={{__html: selected_id?.email_content}} />
                    </Col>
                    
               
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary waves-effect waves-light"
                >
                  Re-send
                </button>
                <button
                  type="button"
                  onClick={() => {
                    tog_standard()
                  }}
                  className="btn btn-primary waves-effect"
                  data-dismiss="modal"
                >
                  Close
                </button>
                
              </div>
              </AvForm>
            </Modal>
          </Col>
      </div>

    </React.Fragment>
  )
}

export default EmailForms
