import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap"
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken";
import { BASE_URL } from "../../helpers/url_helper";
import { get, put } from "../../helpers/api_helper";
import { HereFromOptions, YesNoNAOptions, YesNoOptions } from "./Options";
import { ValidateUser } from "../../helpers/validate_user";

const EditInitialScreening = (props) => {

    const [screening, setScreening] = useState(null);
    const [guarantee, setGuarantee] = useState({ label: "Yes", value: "yes" });
    const [bgcheck, setBgcheck] = useState({ label: "Yes", value: "yes" });
    const [refs, setRefs] = useState({ label: "Yes", value: "yes" });
    const [pay_comm, setPay_comm] = useState({ label: "Yes", value: "yes" });
    const [hear_from, setHear_from] = useState({value: "Cable Channel", label: "Cable Channel"});
    const [didMount, setDidMount] = useState(false); 

    ValidateUser(props)
    useEffect(() => {
        setDidMount(true);
        loadScreeningData()
        return () => setDidMount(false);
        
    },[]);

    if(!didMount) {
        return null;
    }

    function loadScreeningData(){
        get(`${BASE_URL}/api/tgcl/screening/${props.match.params.id}/`,
        { headers: { 'Content-Type': 'application/json', }, validateStatus: false}
        )
        .then(resp => {
            setScreening(resp.data)
            setBgcheck({ label: resp.data.bgcheck, value: resp.data.bgcheck })
            setGuarantee({ label: resp.data.guarantee, value: resp.data.guarantee })
            setHear_from({ label: resp.data.hear_from, value: resp.data.hear_from })
            setPay_comm({ label: resp.data.pay_comm, value: resp.data.pay_comm })
            setRefs({ label: resp.data.refs, value: resp.data.refs })
        })

    }

    function SaveScreeningData(e, values){
        let data = values
        data["guarantee"]= guarantee.value
        data["pay_comm"]= pay_comm.value
        data["bgcheck"]= bgcheck.value
        data["refs"]= refs.value
        data["hear_from"]= hear_from.value

        put(`${BASE_URL}/api/tgcl/screening/${props.match.params.id}/`, data, 
         { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()}, validateStatus: false}
        )
        .then(resp => {
            if (resp.status=== 200){
                props.history.push("/initial-screening")
            }else{
                console.log("Somthing Went Wrong")
            }
        })

    }

    return (
        <React.Fragment>
        <div className="page-content">
            <Breadcrumbs title="Initial Screening" breadcrumbItem={screening?"Edit "+screening.company:"Edit Screening Form"} link="/initial-screening" />
            {screening?
            <Row>
                <Col>
                    <Card>
                    <CardBody>
                        <CardTitle className="h4">Screening Form Detail</CardTitle>
                            <AvForm className="needs-validation" onValidSubmit={SaveScreeningData}>
                                <Row className="mb-3">
                                    <Label htmlFor="company" className="col-md-2 col-form-label">Name of Business <span style={{color:'red'}}>*</span></Label>
                                    <div className="col-md-10">
                                        <AvField
                                            name="company"
                                            placeholder="Business Name"
                                            type="text"
                                            errorMessage="Enter Business Name"
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="company"
                                            value={screening.company}
                                        />
                                    </div>
                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="address" className="col-md-2 col-form-label">Street Addres <span style={{color:'red'}}>*</span></Label>
                                    <div className="col-md-10">
                                        <AvField
                                            name="address"
                                            placeholder="Street Addres"
                                            type="text"
                                            errorMessage="Enter Street Addres"
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="address"
                                            value={screening.address}
                                        />
                                    </div>
                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="city" className="col-md-2 col-form-label">City <span style={{color:'red'}}>*</span></Label>
                                    <div className="col-md-10">
                                        <AvField
                                            name="city"
                                            placeholder="City Name"
                                            type="text"
                                            errorMessage="Enter City Name"
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="city"
                                            value={screening.city}
                                        />
                                    </div>
                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="state" className="col-md-2 col-form-label">State</Label>
                                    <div className="col-md-10">
                                        <AvField
                                            name="state"
                                            placeholder="State"
                                            type="text"
                                            errorMessage="Enter State"
                                            className="form-control"
                                            validate={{ required: { value: true } }}
                                            id="state"
                                            value={screening.state}
                                        />
                                    </div>
                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="zip" className="col-md-2 col-form-label">Zip</Label>
                                    <div className="col-md-10">
                                        <AvField
                                            name="zip"
                                            placeholder="Zip"
                                            type="text"
                                            className="form-control"
                                            id="zip"
                                            value={screening.zip}
                                        />
                                    </div>
                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="contact" className="col-md-2 col-form-label">Contact Person</Label>
                                    <div className="col-md-10">
                                        <AvField
                                            name="contact"
                                            placeholder="Contact Person"
                                            type="text"
                                            className="form-control"
                                            id="contact"
                                            value={screening.contact}
                                        />
                                    </div>
                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="zip" className="col-md-2 col-form-label">Phone Number</Label>
                                    <div className="col-md-10">
                                        <AvField
                                            name="phone"
                                            placeholder="Phone Number"
                                            type="text"
                                            errorMessage="Enter Only Digits"
                                            validate={{
                                                pattern: {
                                                    value: "^[0-9+-]+$",
                                                    errorMessage: "Invalid Contact"
                                                },
                                            }}
                                            value={screening.phone}
                                        />
                                    </div>
                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="email" className="col-md-2 col-form-label">Email</Label>
                                    <div className="col-md-10">
                                        <AvField
                                            className="mb-3"
                                            name="email"
                                            placeholder="Enter Email"
                                            type="email"
                                            errorMessage="Invalid Email"
                                            validate={{
                                                // required: { value: true },
                                                email: { value: true },
                                            }}
                                            id="email"
                                            value={screening.email}
                                        />
                                    </div>
                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="service" className="col-md-2 col-form-label">Field of service such as Air Conditioning, Plumbing, etc</Label>
                                    <div className="col-md-10">
                                    <AvField
                                            name="service"
                                            placeholder="Service"
                                            type="text"
                                            className="form-control"
                                            id="service"
                                            value={screening.service}
                                        />
                                    </div>
                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="trucks" className="col-md-2 col-form-label">How many trucks/crews does your company have?</Label>
                                    <div className="col-md-10">
                                    <AvField
                                            name="trucks"
                                            placeholder="Number of Trucks/Crews"
                                            type="number"
                                            className="form-control"
                                            id="trucks"
                                            value={screening.trucks}
                                        />
                                    </div>
                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="established" className="col-md-2 col-form-label">Year Business Established</Label>
                                    <div className="col-md-10">
                                    <AvField
                                            name="established"
                                            placeholder="Year Business Established"
                                            type="number"
                                            className="form-control"
                                            id="established"
                                            value={screening.established}
                                        />
                                    </div>
                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="exp" className="col-md-2 col-form-label">Years of Experience in Your Field</Label>
                                    <div className="col-md-10">
                                    <AvField
                                            name="exp"
                                            placeholder="Years of Experience in Your Field"
                                            type="number"
                                            className="form-control"
                                            id="exp"
                                            value={screening.exp}
                                        />
                                    </div>
                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="url" className="col-md-2 col-form-label">Website Address</Label>
                                    <div className="col-md-10">
                                    <AvField
                                            name="url"
                                            placeholder="Website Address"
                                            type="text"
                                            className="form-control"
                                            id="url"
                                            value={screening.url}
                                        />
                                    </div>
                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="bbb" className="col-md-2 col-form-label">Better Business Bureau Standing</Label>
                                    <div className="col-md-10">
                                    <AvField
                                            name="bbb"
                                            placeholder="Better Business Bureau Standing"
                                            type="text"
                                            className="form-control"
                                            id="bbb"
                                            value={screening.bbb}
                                        />
                                    </div>
                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="genlib" className="col-md-2 col-form-label">Amount of General Liability Carried</Label>
                                    <div className="col-md-10">
                                    <AvField
                                            name="genlib"
                                            placeholder="Amount of General Liability Carried"
                                            type="text"
                                            className="form-control"
                                            id="genlib"
                                            value={screening.genlib}
                                        />
                                    </div>
                                </Row>

                                <Row className="mb-3">
                                    <Label htmlFor="genlib" className="col-md-2 col-form-label">Do you offer a written 100% Satisfaction Guarantee to your clients?:</Label>
                                    <div className="col-md-10">
                                        <Select
                                            // className="form-control"
                                            value={guarantee}
                                            onChange={(e) => {setGuarantee(e)}}
                                            options={YesNoNAOptions}
                                            classNamePrefix="select2-selection"
                                        />

                                    </div>
                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="genlib" className="col-md-2 col-form-label">Can you provide at least 3 industry professionals that can vouch for your long-term integrity and quality?:</Label>
                                    <div className="col-md-10">
                                        <Select
                                            // className="form-control"
                                            value={refs}
                                            onChange={(e) => {
                                                setRefs(e)
                                            }}
                                            options={YesNoOptions}
                                            classNamePrefix="select2-selection"
                                        />

                                    </div>
                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="genlib" className="col-md-2 col-form-label">Are you willing to undergo background investigations?:</Label>
                                    <div className="col-md-10">
                                        <Select
                                            // className="form-control"
                                            value={bgcheck}
                                            onChange={(e) => {
                                                setBgcheck(e)
                                            }}
                                            options={YesNoOptions}
                                            classNamePrefix="select2-selection"
                                        />

                                    </div>
                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="genlib" className="col-md-2 col-form-label">How did you hear of us?</Label>
                                    <div className="col-md-10">
                                        <Select
                                            // className="form-control"
                                            value={hear_from}
                                            onChange={(e) => {
                                                setHear_from(e)
                                            }}
                                            options={HereFromOptions}
                                            classNamePrefix="select2-selection"
                                        />

                                    </div>
                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="genlib" className="col-md-2 col-form-label">Are your technicians paid a commissions</Label>
                                    <div className="col-md-10">
                                        <Select
                                            // className="form-control"
                                            value={pay_comm}
                                            onChange={(e) => {
                                                setPay_comm(e)
                                            }}
                                            options={YesNoNAOptions}
                                            classNamePrefix="select2-selection"
                                        />

                                    </div>
                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="no_pressure" className="col-md-2 col-form-label">If YES, how do you insure your technicians are not using fear tactics or high pressure to increase their paycheck</Label>
                                    <div className="col-md-10">
                                        <AvField
                                            className="mb-3"
                                            type="textarea"
                                            rows="3"
                                            name="no_pressure"
                                            id="no_pressure"
                                            value={screening.no_pressure}
                                            placeholder="If YES, how do you insure your technicians are not using fear tactics or high pressure to increase their paycheck"
                                        />

                                    </div>
                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="areas_served" className="col-md-2 col-form-label">What area(s) do you serve?</Label>
                                    <div className="col-md-10">
                                        <AvField
                                            className="mb-3"
                                            type="textarea"
                                            rows="3"
                                            name="areas_served"
                                            id="areas_served"
                                            placeholder="What area(s) do you serve?"
                                            value={screening.areas_served}
                                        />

                                    </div>
                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="differences" className="col-md-2 col-form-label">List any details separating your business from your competition?</Label>
                                    <div className="col-md-10">
                                        <AvField
                                            className="mb-3"
                                            type="textarea"
                                            rows="3"
                                            name="differences"
                                            id="differences"
                                            placeholder="List any details separating your business from your competition?"
                                            value={screening.differences}
                                        />
                                    </div>
                                </Row>
                                <Row className="mb-3">
                                    <Label htmlFor="differences" className="col-md-2 col-form-label"></Label>
                                    <div className="col-md-10">
                                        <Button color="primary" type="submit">
                                            Submit
                                        </Button>
                                    </div>
                                </Row>
                            </AvForm>
                    </CardBody>
                    </Card>
                </Col>
            </Row>
            :
            <div id="preloader">
                <div id="status">
                    <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    </div>
                </div>
            </div>
            }

        </div>

        </React.Fragment>
    )
}

export default EditInitialScreening
