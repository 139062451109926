import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap"
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken";

import { BASE_URL } from "../../helpers/url_helper";
import { get, put } from "../../helpers/api_helper";
import { toast } from "react-toastify";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const EditPendingCampaign = (props) => {

    const { id } = props.match.params;
    const [campaign, setCampaign] = useState(null);
    const [selectedCategory, setselectedCategory] = useState({ label: "Select Category", value: null });
    const [selectedContractor, setselectedContractor] = useState({ label: "Select Contractor", value: null });
    const [didMount, setDidMount] = useState(false);
    const [categories, setCategories] = useState([]); 
    const [contractors, setContractors] = useState([]); 
    const [editorState, setEditorState] = useState(EditorState.createEmpty()); 
    const [rawMessage, setrawMessage] = useState(''); 

    const Categories = [
        {
          label: "Categories",
          options: categories,
        },
      ];
    
    const Contractors = [
        {
          label: "Contractors",
          options: contractors
        },
      ];

    useEffect(() => {
        setDidMount(true);
        loadCampaign(null)
        loadCategories()
        loadContractors(null)
        return () => setDidMount(false);
        
    },[]);

    if(!didMount) {
        return null;
      }

    function handleSelectCategory(selectedCategory) {
        setselectedCategory(selectedCategory);
    }

    function handleSelectContractor(selectedContractor) {
        setselectedContractor(selectedContractor);
    }

    function loadCampaign(){
        let data = get(`${BASE_URL}/api/tgcl/tmpcampaign/${id}/`, { 'Content-Type': 'application/json' })
        data.then(response => {
            setCampaign(response.data)
            if(response.data.heading){
                setselectedCategory({ label: response.data.heading.heading, value: response.data.heading.id_num })
            }
            if(response.data.contractor){
                setselectedContractor({ label: response.data.contractor.bus_name, value: response.data.contractor.id_num })
            }
            const { contentBlocks, entityMap } = htmlToDraft(response.data.narrative);
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorrState = EditorState.createWithContent(contentState);
            setrawMessage(draftToHtml(convertToRaw(editorrState.getCurrentContent())))
            setEditorState(editorrState)

    })
}

    function loadCategories(){
        let data = get(`${BASE_URL}/api/tgcl/headings/?page_size=200`,  {  validateStatus: false} )
        data.then(response => {
            
        let options = []
        let cat_data = response.data.results
        for(let i=0; i<cat_data.length; i++){
            options.push({ label: cat_data[i].heading, value: cat_data[i].id_num })
        }
        setCategories(options)
        },
        error => {
            props.history.push('/campaigns')
            toast.error("Somthing Went wrong")

        })
    }

    function loadContractors(search){
        let url = `${BASE_URL}/api/tgcl/contractor/?page_size=200`
        if (search){
            url = `${BASE_URL}/api/tgcl/contractor/?page_size=200&search=${search}`
        }
        let data = get(url)
        data.then(response => {
            
        let options = []
        let cat_data = response.data.results
        for(let i=0; i<cat_data.length; i++){
            options.push({ label: cat_data[i].bus_name, value: cat_data[i].id_num })
        }
        setContractors(options)
        },
        error => {
            props.history.push('/campaigns')
            toast.error("Somthing Went wrong")

        })
    }

    function onEditorStateChange(editorState){
        setEditorState(editorState)
        setrawMessage(draftToHtml(convertToRaw(editorState.getCurrentContent())))
      };

    function handleSubmit(e, values){
        let data = values
        if(!selectedCategory.value){
            toast.warning("Select Category")
        }else if(!selectedContractor.value){
            toast.warning("Select Contractor")
        }else if(new Date(values.start) >= new Date(values.exp)){
            toast.warning("End Date must be Grate than Start Date")
        }else{
            data["omit_add"] = document.getElementById('omit_add').checked?1:0
            data["active"] = document.getElementById('active').checked?1:0
            data["narrative"] = rawMessage
            data["contractor"] = selectedContractor.value
            data["heading"] = selectedCategory.value
            put(`${BASE_URL}/api/tgcl/tmpcampaign/${id}/`, data,   { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()}, validateStatus: false})
            .then(response => {
                setCampaign(response.data)
                if(response.data.heading){
                    setselectedCategory({ label: response.data.heading.heading, value: response.data.heading.id_num })
                }
                if(response.data.contractor){
                    setselectedContractor({ label: response.data.contractor.bus_name, value: response.data.contractor.id_num })
                }
                const { contentBlocks, entityMap } = htmlToDraft(response.data.narrative);
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                const editorrState = EditorState.createWithContent(contentState);
                setrawMessage(draftToHtml(convertToRaw(editorrState.getCurrentContent())))
                setEditorState(editorrState)
                toast.success("Campaign Updated Successfully")
                })
        }
        
    }

    return (
        <React.Fragment>
        <div className="page-content">

            <Breadcrumbs title="Campaigns" breadcrumbItem={`Edit ${campaign?campaign.page_title:"Campaign"}`} link="/campaigns" />
            {campaign?

                <Row>
                    <Col md="12">
                        <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                            <Row>
                            <Col sm="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Campaign Detail </CardTitle>     
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label>Category</Label>
                                                    <Select
                                                    value={selectedCategory}
                                                    onChange={(e) => {
                                                        handleSelectCategory(e);
                                                    }}
                                                    options={Categories}
                                                    classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label>Contractor</Label>
                                                    <Select
                                                    onInputChange = {(e)=> loadContractors(e)}
                                                    value={selectedContractor}
                                                    onChange={(e) => {
                                                        handleSelectContractor(e);
                                                    }}
                                                    options={Contractors}
                                                    classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </Col>
                                        </Row> 
                                        <Row>
                                        </Row>                            
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="phone"
                                                        label="Phone"
                                                        placeholder="Contact Number(+91-9999999999)"
                                                        type="text"
                                                        errorMessage="Enter Only Digits"
                                                        validate={{
                                                            pattern: {
                                                                value: "^[0-9+-]+$",
                                                                errorMessage: "Invalid Contact"
                                                            },
                                                        }}
                                                        value={campaign.phone}
                                                        />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                <Label htmlFor="validationCustom03">City</Label>
                                                <AvField
                                                    name="city"
                                                    placeholder="City"
                                                    type="text"
                                                    errorMessage=" Please provide a valid city."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="validationCustom03"
                                                    value={campaign.page_title}
                                                />
                                                </div>
                                            </Col>
                                        
                                        </Row>
                                        <Row>
                                            
                                            <Col md="6">
                                                <div className="mb-3">
                                                <Label htmlFor="validationCustom05">Zip</Label>
                                                <AvField
                                                    name="zip"
                                                    placeholder="Zip Code"
                                                    type="text"
                                                    errorMessage=" Please provide a valid zip."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="validationCustom05"
                                                    value={campaign.zip}
                                                />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="zone"
                                                        label="Zone"
                                                        placeholder="Enter Zone"
                                                        type="text"
                                                        errorMessage="Enter Only Digits"
                                                        validate={{
                                                            pattern: {
                                                                value: "^[0-9]+$",
                                                                errorMessage: "Invalid Zone"
                                                            },
                                                        }}
                                                        value={campaign.zone}
                                                        />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <AvField
                                                        className="mb-3"
                                                        name="start"
                                                        label="Start Date"
                                                        placeholder="Select Start Date"
                                                        type="date"
                                                        errorMessage="Invalid Email"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        value={campaign.start}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <AvField
                                                        className="mb-3"
                                                        name="exp"
                                                        label="End Date"
                                                        placeholder="Select End Date"
                                                        type="date"
                                                        errorMessage="Invalid Email"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        value={campaign.exp}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="membership_fee"
                                                        label="Membership Fee"
                                                        placeholder="Enter Membership Fee"
                                                        type="text"
                                                        errorMessage="Enter Only Digits"
                                                        validate={{
                                                            pattern: {
                                                                value: "^[0-9]+$",
                                                                errorMessage: "Invalid Membership Fee"
                                                            },
                                                        }}
                                                        value={campaign.membership_fee}
                                                        />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="monthly_payment"
                                                        label="Monthly Payment"
                                                        placeholder="Enter Monthly Payment"
                                                        type="text"
                                                        errorMessage="Enter Only Digits"
                                                        validate={{
                                                            pattern: {
                                                                value: "^[0-9]+$",
                                                                errorMessage: "Invalid Monthly Payment"
                                                            },
                                                        }}
                                                        value={campaign.monthly_payment}
                                                        />
                                                </div>
                                            </Col>
                                        </Row>
                                    
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="6">
                                <Card>
                                    <CardBody>
                                        {/* <CardTitle>Contractor Detail </CardTitle>                   */}
                                        
                                            <Row>
                                                <Col md="12">
                                                    <div className="mb-3">
                                                    <Label htmlFor="page_title">Page Title</Label>
                                                    <AvField
                                                        name="page_title"
                                                        placeholder="Page Title"
                                                        type="text"
                                                        errorMessage="Enter Page Title"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="page_title"
                                                        value={campaign.pg_title}
                                                    />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <AvField
                                                    className="mb-3"
                                                    type="textarea"
                                                    label="Page Description "
                                                    rows="3"
                                                    name="page_description"
                                                    id="page_description"
                                                    placeholder="Page Description"
                                                    value={campaign.pg_desc}
                                                />
                                            </Row>
                                            
                                            <Row>
                                                <Col md="12">
                                                    <div className="mb-3">
                                                    <Label htmlFor="keywords">Keywords & Phrases</Label>
                                                    <AvField
                                                        name="keywords"
                                                        placeholder="Keywords & Phrases"
                                                        type="text"
                                                        className="form-control"
                                                        id="keywords"
                                                        value={campaign.keyword}
                                                    />
                                                    </div>
                                                </Col>
                                            </Row>
                                            
                                            <Row>
                                                <Col md="12" className="mb-3">
                                                <Label >Narrative</Label>
                                                    <Editor       
                                                    toolbar={{
                                                        options: ['inline','fontSize'],
                                                        inline: {
                                                        options: ['bold', 'italic', 'underline'],
                                                        },
                                                    }}      
                                                    editorState={editorState}            
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    onEditorStateChange={onEditorStateChange}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <div className="form-check mb-3">
                                                        <input className="form-check-input" type="checkbox" defaultChecked={campaign.omit_add===1?true:false} id="omit_add"
                                                            required />{" "}
                                                        <label className="form-check-label" htmlFor="omit_add">
                                                        Omit Address</label>
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="form-check mb-3">
                                                        <input className="form-check-input" type="checkbox" defaultChecked={campaign.active===1?true:false} id="active"
                                                            required />{" "}
                                                        <label className="form-check-label" htmlFor="active">
                                                        Active</label>

                                                    </div>
                                                </Col>
                                            </Row>
                                            <Button color="primary my-3" type="submit">
                                            Update
                                            </Button>
                                    </CardBody>
                                </Card>
                            </Col>

                            </Row>
                            
                        </AvForm>
                    </Col>
                </Row>
            :
            <div id="preloader">
                <div id="status">
                    <div className="spinner-chase">
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                    </div>
                </div>
            </div>
            }
        </div>

        </React.Fragment>
    )
}

export default EditPendingCampaign
