import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Label, Button, Modal } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Select from "react-select";
import { Link } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
import avatar4 from "../../assets/images/not_avail.jpg"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {getAccessToken} from "../../helpers/jwt-token-access/accessToken"
import Switch from "react-switch";
import { BASE_URL } from "../../helpers/url_helper";
import { get, put, del } from "../../helpers/api_helper";
import { toast } from "react-toastify";
import { EditorState, convertToRaw, ContentState, left, right, center, justify, undo, redo, bold, italic, underline,
    ordered, indent, outdent, link, unlink, eraser, unordered } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { HeaderFormatter } from "../../helpers/methods";
import ActivityLog from "../ActivityLog/ActivityLog";


const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };
  
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  

const EditCampaign = (props) => {
    const contractorId = props.location.contractorId
    const { id } = props.match.params;
    const [campaign, setCampaign] = useState(null);
    const [selectedCategory, setselectedCategory] = useState({ label: "Select Category", value: null });
    const [selectedSubCats, setSelectedSubCats] = useState([]);
    const [selectedContractor, setselectedContractor] = useState({ label: "Select Contractor", value: null });
    const [didMount, setDidMount] = useState(false);
    const [categories, setCategories] = useState([]); 
    const [childCategories, setChildCategories] = useState([])
    const [contractors, setContractors] = useState([]); 
    const [editorState, setEditorState] = useState(EditorState.createEmpty()); 
    const [rawMessage, setrawMessage] = useState(''); 
    const [status, setStatus] = useState(false);
    const [gallery, setGallery] = useState([]);
    const [selected_id, setSelected_id] = useState(null)
    const [success_confirm, setSuccess_confirm] = useState(false)
    const [alert_confirm, setAlert_confirm] = useState(false)
    const [dynamic_title, setDynamic_title] = useState("")
    const [dynamic_description, setDynamic_description] = useState("")
    const [modal_standard, setmodal_standard] = useState(false)
    const [file, setFile] = useState(null);
    const [logo, setLogo] = useState(null);
    const [background, setBackground] = useState(null);
    const [submitting, setSubmitting] = useState(null);
    const [submittingGallery, setSubmittingGallery] = useState(null);
    const [phone, setPhone] = useState("")
    const [imageError, setImageError] = useState("")

    function tog_standard() {
        setmodal_standard(!modal_standard)
        removeBodyCss()
      }
      function removeBodyCss() {
        document.body.classList.add("no_padding")
      }

    const columns = [
        {
          dataField: "id",
          text: "ID", 
          hidden: true,
        },
        {
          dataField:"img_path",
          text: 'Image',
          headerFormatter:HeaderFormatter,
          formatter: (cellContent) => (
            <a href={cellContent} rel="noreferrer" target={"_blank"} ><img src={cellContent?cellContent:avatar4} className="rounded avatar-md"  alt="" /></a>
          ),     
        },
        {
          dataField: "is_active",
          text: "Active",
          headerFormatter:HeaderFormatter,
          formatter: (cellContent, row) => (
            <input type='checkbox' defaultChecked={cellContent===1?true:false} onChange={(e)=> changeActive(e.target.checked, row.id )}></input>
          ),
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          headerFormatter:HeaderFormatter,
          formatter: (cellContent, row) => (
            <React.Fragment>
              <Link to="#" onClick={() => {setAlert_confirm(true); setSelected_id(row.id)}} className="text-danger"><i className="mdi mdi-trash-can font-size-18"></i></Link>
            </React.Fragment>
          ),
        },
      ]

    const Categories = [
        {
          label: "Categories",
          options: categories,
        },
      ];
    
    const Contractors = [
        {
          label: "Contractors",
          options: contractors
        },
      ];
    
    const SubCategories = [
        {
            label: "Sub Categories",
            options: childCategories
        },
        ];

    useEffect(() => {
        setDidMount(true);
        loadCampaign(null)
        loadCategories()
        return () => setDidMount(false);
        
    },[]);

    if(!didMount) {
        return null;
      }

    function handleSelectCategory(selectedCategory) {
        setSelectedSubCats([])
        loadChildCategories(selectedCategory.value, true)
        
        setselectedCategory(selectedCategory);
    }

    function handleSelectContractor(selectedContractor) {
        setselectedContractor(selectedContractor);
    }

    function loadCampaign(){
        let data = get(`${BASE_URL}/api/tgcl/dashboard/campaign/${id}/`, { 'Content-Type': 'application/json' , validateStatus: false })
        data.then(response => {
            if(response.status >= 400){
                props.history.goBack()
            }else{
                console.log(response.data,"response.data")
                setCampaign(response.data)
                loadGallery(response.data.id)
                
                if(response.data.category){
                    setselectedCategory({ label: response.data.category.heading, value: response.data.category.id_num })
                    loadChildCategories(response.data.category.id_num)
                }
                if(response.data.contractor){
                    setselectedContractor({ label: response.data.contractor.bus_name, value: response.data.contractor.id })
                }
                let options = []
                for(let i=0; i< response.data.sub_cats.length; i++){
                    options.push({ label: response.data.sub_cats[i].heading, value: response.data.sub_cats[i].id_num })
                }
                
                const { contentBlocks, entityMap } = htmlToDraft(response.data.narrative);
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                const editorrState = EditorState.createWithContent(contentState);
                setrawMessage(draftToHtml(convertToRaw(editorrState.getCurrentContent())))
                setEditorState(editorrState)
                setStatus(response.data.status)
                setSelectedSubCats(options)
                setPhone(response.data.phone)

            }
            

    })
    }

    function addPhoneNumber(id){
        let area_code = prompt("Please enter a three-digit area code:");
        if (area_code !== '' && area_code !== undefined)
        {
            get(`${BASE_URL}/api/tgcl/dashboard/add-phone-number/?area_code=${area_code}`, { 'Content-Type': 'application/json',  validateStatus: false } )
                .then(response => {
                    if(response.status >= 400){
                        console.log(response)
                        toast.error(`${response.data? response.data.message: "Somthing went Wrong"}`)
                        
                    }else{
                        let num = response.data.friendly_name
                        num = num.replace("(", "")
                        num = num.replace(" ", "")
                        num = num.replace(")", "-")
                        setPhone(num)
                        
                    }
                
                })
        }
        else
        {
          alert("Acquire canceled.");
        }

    }

    function releasePhoneNumber(id){
        
        let number = phone
        if (number !== ""){
            number = number.replaceAll("-", "")
            number = number[0] === "+"? number : `+1${number}`
            if (window.confirm("Are you sure you want to decomission this number?  This cannot be undone."))
            {
                get(`${BASE_URL}/api/tgcl/dashboard/delete-phone-number/?phone_number=${number}`, { 'Content-Type': 'application/json' } )
                .then(response => {
                    if(response.status >= 400){
                        console.log(response)
                        toast.error("Somthing went Wrong")   
                    }
                })
                
                setPhone("")  
            }
        }  
    }

    function loadGallery(id){
        get(`${BASE_URL}/api/tgcl/gallery/?campaign_id=${id}&page_size=200`,  {  validateStatus: false} )
        .then(response => {
            setGallery(response.data.results)
        }
       )

    }

    function changeActive(active, id){
        put(`${BASE_URL}/api/tgcl/gallery/${id}/`, {'is_active':active?1:0}, 
            { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()}, validateStatus: false}
        )
        .then(response => {
        })
    }

    function handleImageClick(id){
        del(`${BASE_URL}/api/tgcl/gallery/${id}/`, 
        { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()}, validateStatus: false}
    )
    .then(res => 
          {if(res.status >= 400){
            console.log("error")
          }
          else{
            loadGallery(campaign.id)
            setSuccess_confirm(true)
            setAlert_confirm(false)
            setDynamic_title('Deleted!')
            setDynamic_description("Images Has been deleted.") 
            setSelected_id(null)
          }
      }
    )
    }
    function formatBytes(bytes, decimals = 2){
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
      };

    function handleChange(e) {
        if(e.target.files[0].size >500000){
            setImageError(`Image Size must be less than 500 Kb (Uploaded ${formatBytes(e.target.files[0].size)})`)

        }else{
            setFile(e.target.files[0]);
            setImageError("")
        }
        
        
    }
    function changeLogo(e, setData) {
        setData(e.target.files[0]);
    }
    function handleImageSubmit(e, values){
        if(!file){
            toast.error("Select Image")
        }else{
            setSubmittingGallery(true)
            const formData = new FormData();
        formData.append('img_path', file, file.name) 
        formData.append("title",values.title)
        formData.append("campaign_id",campaign.id)
        formData.append("is_active",document.getElementById('is_active').checked?1:0)

        const options = {
            method: 'POST',
            body: formData,
            headers: {'Authorization': getAccessToken() }
        };
        fetch(`${BASE_URL}/api/tgcl/gallery/`, options)
            .then(res => 
                {if(res.status >= 400){
                    res.json()
                    .then(
                        (data) =>{  
                            console.log(data, "---data")
                            toast.error("Somthing Went wrong")
                            tog_standard()
                            setSubmittingGallery(false)
                        }      
                    )
                }
                else{
                    tog_standard()
                    loadGallery(campaign.id)
                    setSubmittingGallery(false)
                }    
            }
        ) 

        }
    }

    function loadChildCategories(id, is_change=null){
        let data = get(`${BASE_URL}/api/tgcl/headings/?parent_id=${id}&page_size=50`,  {  validateStatus: false} )
        data.then(response => {
            
        let options = []
        let cat_data = response.data.results
        for(let i=0; i<cat_data.length; i++){
            options.push({ label: cat_data[i].heading, value: cat_data[i].id_num })
        }

        setChildCategories(options)
        if(is_change){
            setSelectedSubCats(options)
        }
        
        },
        error => {
            props.history.push('/campaigns')
            toast.error("Somthing Went wrong")

        })

    }

    function loadCategories(){
        let data = get(`${BASE_URL}/api/tgcl/headings/?parent_id=1&active=1&page_size=200`,  {  validateStatus: false} )
        data.then(response => {
            
        let options = []
        let cat_data = response.data.results
        for(let i=0; i<cat_data.length; i++){
            options.push({ label: cat_data[i].heading, value: cat_data[i].id_num })
        }
        setCategories(options)
        
        
        },
        error => {
            props.history.push('/campaigns')
            toast.error("Somthing Went wrong")

        })
    }

    function loadContractors(search){
        let url = `${BASE_URL}/api/tgcl/all-contractors/?approved=true&page_size=200`
        if (search){
            url = `${BASE_URL}/api/tgcl/all-contractors/?approved=true&page_size=200&search=${search}`
        }
        let data = get(url)
        data.then(response => {
            
        let options = []
        let cat_data = response.data.results
        for(let i=0; i<cat_data.length; i++){
            options.push({ label: cat_data[i].bus_name, value: cat_data[i].id })
        }
        setContractors(options)
        },
        error => {
            props.history.push('/campaigns')
            toast.error("Somthing Went wrong")

        })
    }

    function onEditorStateChange(editorState){
        setEditorState(editorState)
        setrawMessage(draftToHtml(convertToRaw(editorState.getCurrentContent())))
      };

    function handleSubmit(e, values){
        if(!selectedCategory.value){
            toast.error("Select Category")
        }else if(!selectedContractor.value){
            toast.error("Select Contractor")
        }else{
            setSubmitting(true)
            const formData = new FormData();
            if (logo){
                formData.append('logo', logo, logo.name) 
            }
            if (background){
                formData.append('background', background, background.name) 
            }
            for(let i=0; i< selectedSubCats.length; i++){
                formData.append('sub_cats', selectedSubCats[i].value)
            }
            formData.append('omit_add', document.getElementById('omit_add').checked?1:0)
            formData.append('active', document.getElementById('active').checked?1:2)
            formData.append('narrative', rawMessage)
            formData.append('contractor', selectedContractor.value)
            formData.append('category', selectedCategory.value)
            formData.append('status', status)
            formData.append('page_title', values.city)
            formData.append('email', values.email)
            formData.append('exp', values.exp)
            formData.append('keyword', values.keyword)
            formData.append('pg_desc', values.pg_desc)
            formData.append('pg_title', values.pg_title)
            formData.append('phone', values.phone)
            formData.append('contact_phone', values.contact_phone)
            formData.append('start', values.start)
            formData.append('url', values.url)
            formData.append('zip', values.zip)
            formData.append('zone', values.zone)
            formData.append('email2', values.email2)
            formData.append('email3', values.email3)
            const options = {
                method: 'PATCH',
                body: formData,
                headers: { 'Authorization': getAccessToken()}
            };
            fetch(`${BASE_URL}/api/tgcl/dashboard/campaign/${id}/`, options)
            .then(response => 
                {   if(response.status >= 400){
                        response.json()
                        .then(
                            (data) =>{  
                                if (data.slug){
                                    toast.error("Campaign already exist with same contractor, category and zip code")
                                    setSubmitting(false)

                                }else{
                                    toast.error("Somthing Went Wrong")
                                    setSubmitting(false)

                                }
                            }    
                        )
                    }
                    else{
                        response.json()
                        .then(
                            (data) =>{
                            if(data.category){
                                setselectedCategory({ label: data.category.heading, value: data.category.id_num })
                            }
                            if(data.contractor){
                                setselectedContractor({ label: data.contractor.bus_name, value: data.contractor.id })
                            }
                            const { contentBlocks, entityMap } = htmlToDraft(data.narrative);
                            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                            const editorrState = EditorState.createWithContent(contentState);
                            setrawMessage(draftToHtml(convertToRaw(editorrState.getCurrentContent())))
                            setEditorState(editorrState)
                            setSubmitting(false)
                            toast.success("Campaign Updated Successfully")
                            setLogo(false)
                            setBackground(false)
                    })
                    } 
                   
                }
            )
        }
        
    }

    return (
        <React.Fragment>
        <div className="page-content">

            <Breadcrumbs title={contractorId? `Contractor Detail`:"Campaigns"} breadcrumbItem={`Edit Campaign`} link={contractorId? `/contractor/${contractorId}`:"/campaigns"} data={contractorId?"2":null} />
            {campaign?
                    <>
                <Row>
                    <Col md="12">
                        <AvForm className="needs-validation" onValidSubmit={handleSubmit}>
                            <Row>
                            <Col sm="6">
                                <Card>
                                    <CardBody>
                                        <CardTitle>Campaign Detail </CardTitle>     
                                        <Row>
                                            <Col md="12">
                                                <div className="mb-3">
                                                    <Label>Category</Label>
                                                    <Select
                                                    value={selectedCategory}
                                                    onChange={(e) => {
                                                        handleSelectCategory(e);
                                                    }}
                                                    options={Categories}
                                                    classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </Col>
                                            
                                           
                                        </Row> 
                                        <Row>
                                            <Col md="12">
                                                <div className="mb-3">
                                                    <Label>Sub Categories</Label>
                                                    <Select
                                                    value={selectedSubCats}
                                                    onChange={(e) => {
                                                        setSelectedSubCats(e)
                                                    }}
                                                    options={SubCategories}
                                                    isMulti={true}
                                                    classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        
                                        
                                        <Row>
                                            <Col md="12">
                                                <div className="mb-3">
                                                    <Label>Contractor</Label>
                                                    <Select
                                                    onInputChange = {(e)=> loadContractors(e)}
                                                    value={selectedContractor}
                                                    onChange={(e) => {
                                                        handleSelectContractor(e);
                                                    }}
                                                    options={Contractors}
                                                    classNamePrefix="select2-selection"
                                                    isDisabled = {true}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>  
                                        
                                        <Row>
                                            <Col md="12">
                                                <div className="mb-3">
                                                <Label htmlFor="url">Site URL</Label>
                                                <AvField
                                                    name="url"
                                                    type="text"
                                                    errorMessage=" Please Enter Site URL."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="url"
                                                    value={campaign.url}
                                                />
                                                </div>
                                            </Col>
                                        </Row>                        
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="phone"
                                                        label="Tracking Phone"
                                                        type="text"
                                                        errorMessage="Enter Only Digits"
                                                        validate={{
                                                            pattern: {
                                                                value: "^[0-9+-]+$",
                                                                errorMessage: "Invalid Contact"
                                                            },
                                                        }}
                                                        value={phone}
                                                        onChange={(e)=> e.target.value}
                                                        />
                                                        <Link to={'#'}  onClick={()=>addPhoneNumber('phone') } className="text-primary" style={{fontSize:'10px'}}>Acquire New Number</Link> | <Link to={'#'} onClick={()=> releasePhoneNumber('phone')} className="text-primary" style={{fontSize:'10px'}}>Release Number</Link>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="contact_phone"
                                                        label="Target Phone"
                                                        type="text"
                                                        errorMessage="Enter Only Digits"
                                                        validate={{
                                                            pattern: {
                                                                value: "^[0-9+-]+$",
                                                                errorMessage: "Invalid Contact"
                                                            },
                                                        }}
                                                        value={campaign.contact_phone}
                                                        />
                                                </div>
                                            </Col>
                                            
                                        
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                <Label htmlFor="validationCustom03">Contact Email</Label>
                                                <AvField
                                                    name="email"
                                                    type="email"
                                                    errorMessage=" Please provide Email."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="email"
                                                    value={campaign.email}
                                                />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                <Label htmlFor="email2">Additional Email 1</Label>
                                                <AvField
                                                    name="email2"
                                                    type="email"
                                                    
                                                    className="form-control"
                                                    
                                                    id="email2"
                                                    value={campaign.email2}
                                                />
                                                </div>
                                            </Col>
                                            
                                        </Row>  

                                        <Row>
                                        <Col md="6">
                                                <div className="mb-3">
                                                <Label htmlFor="email3">Additional Email 2</Label>
                                                <AvField
                                                    name="email3"
                                                    type="email"
                                                    
                                                    className="form-control"
                                                    
                                                    id="email3"
                                                    value={campaign.email3}
                                                />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                <Label htmlFor="validationCustom03">City</Label>
                                                <AvField
                                                    name="city"
                                                    type="text"
                                                    errorMessage=" Please provide a valid city."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="validationCustom03"
                                                    value={campaign.page_title}
                                                />
                                                </div>
                                            </Col>
                                            
                                        </Row>  
                                        <Row>
                                            
                                            <Col md="6">
                                                <div className="mb-3">
                                                <Label htmlFor="validationCustom05">Zip</Label>
                                                <AvField
                                                    name="zip"
                                                    type="text"
                                                    errorMessage=" Please provide a valid zip."
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="validationCustom05"
                                                    value={campaign.zip}
                                                />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="zone"
                                                        label="Zone"
                                                        type="text"
                                                        errorMessage="Enter Only Digits"
                                                        validate={{
                                                            pattern: {
                                                                value: "^[0-9]+$",
                                                                errorMessage: "Invalid Zone"
                                                            },
                                                        }}
                                                        value={campaign.zone}
                                                        />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <AvField
                                                        className="mb-3"
                                                        name="start"
                                                        label="Start Date"
                                                        type="date"
                                                        value={campaign.start}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <AvField
                                                        className="mb-3"
                                                        name="exp"
                                                        label="End Date"
                                                        type="date"
                                                        value={campaign.exp}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="6">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md="12">
                                                <div className="mb-3">
                                                <Label htmlFor="pg_title">Page Title</Label>
                                                <AvField
                                                    name="pg_title"
                                                    type="text"
                                                    errorMessage="Enter Page Title"
                                                    className="form-control"
                                                    validate={{ required: { value: true } }}
                                                    id="pg_title"
                                                    value={campaign.pg_title}
                                                />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12" className="mb-3">
                                            <Label >Narrative</Label>
                                                <Editor  
                                                toolbar={{
                                                    options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'remove', 'history'],
                                                    inline: {
                                                      inDropdown: false,
                                                      className: undefined,
                                                      component: undefined,
                                                      dropdownClassName: undefined,
                                                      options: ['bold', 'italic', 'underline'],
                                                      bold: { icon: bold, className: undefined },
                                                      italic: { icon: italic, className: undefined },
                                                      underline: { icon: underline, className: undefined },
                                                    },
                                                    blockType: {
                                                      inDropdown: true,
                                                      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                                                      className: undefined,
                                                      component: undefined,
                                                      dropdownClassName: undefined,
                                                    },
                                                
                                                    list: {
                                                      inDropdown: false,
                                                      className: undefined,
                                                      component: undefined,
                                                      dropdownClassName: undefined,
                                                      options: ['unordered', 'ordered', 'indent', 'outdent'],
                                                      unordered: { icon: unordered, className: undefined },
                                                      ordered: { icon: ordered, className: undefined },
                                                      indent: { icon: indent, className: undefined },
                                                      outdent: { icon: outdent, className: undefined },
                                                    },
                                                    textAlign: {
                                                      inDropdown: false,
                                                      className: undefined,
                                                      component: undefined,
                                                      dropdownClassName: undefined,
                                                      options: ['left', 'center', 'right', 'justify'],
                                                      left: { icon: left, className: undefined },
                                                      center: { icon: center, className: undefined },
                                                      right: { icon: right, className: undefined },
                                                      justify: { icon: justify, className: undefined },
                                                    },
                                                    
                                                    link: {
                                                      inDropdown: false,
                                                      className: undefined,
                                                      component: undefined,
                                                      popupClassName: undefined,
                                                      dropdownClassName: undefined,
                                                      showOpenOptionOnHover: true,
                                                      defaultTargetOption: '_self',
                                                      options: ['link', 'unlink'],
                                                      link: { icon: link, className: undefined },
                                                      unlink: { icon: unlink, className: undefined },
                                                      linkCallback: undefined
                                                    },
                                                    remove: { icon: eraser, className: undefined, component: undefined },
                                                    history: {
                                                      inDropdown: false,
                                                      className: undefined,
                                                      component: undefined,
                                                      dropdownClassName: undefined,
                                                      options: ['undo', 'redo'],
                                                      undo: { icon: undo, className: undefined },
                                                      redo: { icon: redo, className: undefined },
                                                    },
                                                  }}      
                                                editorState={editorState}            
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={onEditorStateChange}
                                                />
                                            </Col>
                                        </Row>
                                    
                                        
                                        <Row>
                                            <AvField
                                                className="mb-3"
                                                type="textarea"
                                                label="Page Description "
                                                rows="3"
                                                name="pg_desc"
                                                id="page_description"
                                                value={campaign.pg_desc}
                                            />
                                        </Row>
                                        
                                        <Row>
                                            <Col md="12">
                                                <div className="mb-3">
                                                <Label htmlFor="keywords">Keywords & Phrases</Label>
                                                <AvField
                                                    name="keyword"
                                                    type="text"
                                                    className="form-control"
                                                    id="keywords"
                                                    value={campaign.keyword}
                                                />
                                                </div>
                                            </Col>
                                        </Row>
                                        
                                        
                                        <Row>
                                            <Col md="6">
                                                <div className="form-check mb-3">
                                                    <input className="form-check-input" type="checkbox" defaultChecked={campaign.omit_add===1?true:false} id="omit_add"
                                                        required />{" "}
                                                    <label className="form-check-label" htmlFor="omit_add">
                                                    Omit Address</label>
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="form-check mb-3">
                                                    <input className="form-check-input" type="checkbox" defaultChecked={campaign.active===1?true:false} id="active"
                                                        required />{" "}
                                                    <label className="form-check-label" htmlFor="active">
                                                    Active</label>

                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                                
                                            <Col md={6}>
                                            <label className="form-check-label me-3">Is Approved Campaign</label>
                                            <Switch
                                                uncheckedIcon={<Offsymbol />}
                                                checkedIcon={<OnSymbol />}
                                                onColor="#45cb85"
                                                offColor="#ff715b"
                                                className="form-label, my-2"
                                                onChange={() => {
                                                setStatus(!status);
                                                }}
                                                checked={status}
                                            />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label htmlFor="icon">Logo</Label>
                                            <Col md="4">
                                                <input id="logo" className="" type="file" onChange={(e)=> changeLogo(e, setLogo)} />
                                            </Col>
                                            <Col md="8">
                                                {logo?
                                                    <img src={URL.createObjectURL(logo)} alt="" style={{width:"50%"}} className="m-3"/>
                                                : campaign.logo?
                                                    <img src={campaign.logo} alt="" style={{width:"50%"}} className="m-3"/>
                                                :null}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label htmlFor="background">BackGround Image</Label>
                                            <Col md="4">
                                                <input id="background" className="" type="file" onChange={(e)=> changeLogo(e, setBackground)} />
                                            </Col>
                                            <Col md="8">
                                                {background?
                                                    <img src={URL.createObjectURL(background)} alt="" style={{width:"50%"}} className="m-3"/>
                                                : campaign.background?
                                                    <img src={campaign.background} alt="" style={{width:"50%"}} className="m-3"/>
                                                :null}
                                            </Col>
                                        </Row>
                                        {submitting?
                                            <button
                                            type="button"
                                            className="btn btn-primary waves-effect waves-light my-3"
                                            >
                                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                              Updating
                                            </button>
                                            :
                                            <Button color="primary my-3" type="submit">
                                                Update
                                            </Button>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>

                            </Row>
                            
                        </AvForm>
                    </Col>
                </Row>
                <Row>
                   
                    <Col md="6">
                    <Card>
                    <CardBody>
                        <CardTitle>Gallery </CardTitle>  
                    <div className="table-responsive">

                        <BootstrapTable 
                            bootstrap4 
                            keyField="id"
                            data={ gallery } 
                            columns={ columns }
                            bordered={false}
                            striped={true}
                            hover
                            classes={
                            "table align-middle table-nowrap table-check"
                            }
                            headerWrapperClasses={"table-light"} />
                    </div>

                    <Button color="primary my-3" type="button" onClick={() => {tog_standard(); setFile(null)}}>
                        Add Images
                    </Button>
                    </CardBody>
                    </Card>
                    </Col>
                    

                </Row>
                <ActivityLog key={'activity-log'}
                id={id}
                url = {'/api/tgcl/dashboard/campaign-logs/'}
                filter = {'campaign'} />
                </>
            :
            <div id="preloader">
                <div id="status">
                    <div className="spinner-chase">
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                        <div className="chase-dot"></div>
                    </div>
                </div>
            </div>
            }
        </div>
        {alert_confirm ? (
            <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => handleImageClick(selected_id)}
            onCancel={() =>
              { setAlert_confirm(false) 
                setSelected_id(null)
              }
            }
            >
            You won't be able to revert this!
            </SweetAlert>
          ) : null}

        {success_confirm ? (
            <SweetAlert
            success
            title={dynamic_title}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => 
              { setSuccess_confirm(false) 
                setAlert_confirm(false)
              }
            }
            >
                {dynamic_description}
            </SweetAlert>
            )
          : null
        }
        <Col sm={6} md={4} xl={3}>
        <Modal
          isOpen={modal_standard}
          toggle={() => {
            tog_standard()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">{`Add New Image`} </h5>     

            <button
              type="button"
              onClick={() => {
                setmodal_standard(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <AvForm className="needs-validation" onValidSubmit={handleImageSubmit}>
            <div className="modal-body">
            <Row>
                <Col md="12">
                <div className="mb-3">
                    <Label htmlFor="heading">Title</Label>
                    <AvField
                    name="title"
                    type="text"
                    errorMessage="Enter Title"
                    className="form-control"
                    id="Title"
                    />
                </div>
                </Col>
            </Row>
            <Row>
                <Label htmlFor="icon">Image</Label>
                <Col sm={6}>
                    <input id="icon" className="mb-3" type="file" accept="image/png, image/gif, image/jpeg" required onChange={handleChange} />
                    
                </Col>
                {file?
                <Col sm={6}>
                    <img src={URL.createObjectURL(file)} className="rounded avatar-lg m-2" alt="" />
                </Col>: null}
                {imageError !== ""?
                <p style={{color:"red",fontSize:"12px"}}>{imageError}</p>:null}
                
            </Row>
            <Row>
                <Col md="6">
                    <div className="form-check mb-3">
                        <input className="form-check-input" type="checkbox" id="is_active"
                            required />{" "}
                        <label className="form-check-label" htmlFor="is_active">
                        Active </label>
                    </div>
                </Col>
                
            </Row>
                
            </div>
            <div className="modal-footer">
            {submittingGallery?
                <button
                type="button"
                className="btn btn-primary waves-effect waves-light my-3"
                >
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                    Submitting
                </button>
                :
                <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-light"
                    >
                    Submit
                </button>
            }
            
            <button
              type="button"
              onClick={() => {
                tog_standard()
              }}
              className="btn btn-primary waves-effect"
              data-dismiss="modal"
            >
              Close
            </button>
            
          </div>
          </AvForm>

        </Modal>
      </Col>
        </React.Fragment>
    )
}

export default EditCampaign
