import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  UncontrolledAlert,
  CardBody,
  Button,
  Label,
} from "reactstrap"

import { getAccessToken } from "../../helpers/jwt-token-access/accessToken";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import { BASE_URL } from "../../helpers/url_helper";
import { put } from "../../helpers/api_helper";
import { ValidateUser } from "../../helpers/validate_user";
import avatar from "../../assets/images/users/avatar-7.jpg"
// actions
const UserType = [
  {
    label: "Type",
    options: [
      { label: "Super Admin", value: 0 },
      { label: "Sub Admin", value: 1 },
    ],
  },
];

const UserProfile = props => {

  const [user, setUser] = useState(null)
  const [alert, setAlert] = useState({"error": false, "success":false, "error_msg":"", "success_msg":"" })
  const [userType, setUserType] = useState()
  useEffect(() => {
    ValidateUser(props)
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      obj.user.role ===0? setUserType({ label: "Super Admin", value: 0 }): setUserType({ label: "Sub Admin", value: 1 })
      setUser(obj.user)
    }
  }, [])
  
  function handleValidSubmit(event, values) {
    let form_data = values
    form_data['role'] = user.role
    put(`${BASE_URL}/api/accounts/users/${user.id}/`,
    // JSON.stringify(values),
    form_data,
     { headers: { 'Content-Type': 'application/json', 'Authorization': getAccessToken()}, validateStatus: false})
    .then(resp => {
      if (resp.status >= 400){
        console.log(resp)
        setAlert({"error": true, "success":false, "error_msg":"Somthing Went Wrong", "success_msg":"" })
      }else{
        setAlert({"error": false, "success":true, "error_msg":"", "success_msg":"Profile Has Been Updated" })
        let userdata = resp.data
        const obj = JSON.parse(localStorage.getItem("authUser"))
        let data = obj
        data.user = userdata
        localStorage.setItem("authUser", JSON.stringify(data))
      }
      

    })
    
  }

  return (
    <React.Fragment>
      <div className="page-content">
        
          {/* Render Breadcrumb */}
          <Breadcrumb title="Dashboard" breadcrumbItem="Profile" link="/dashboard"/>
          {user?
          <>
            <Row>
              <Col lg="12">
                { alert.error? (
                  <UncontrolledAlert color="danger" className="alert-dismissible fade show" role="alert">{alert.error_msg}</UncontrolledAlert>
                ) : null}
                {alert.success ? (
                  <UncontrolledAlert color="success" className="alert-dismissible fade show" role="alert">{alert.success_msg}</UncontrolledAlert>
                ) : null}

                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="ms-3">
                        <img
                          src={avatar}
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>
                      <div className="flex-1 align-self-center">
                        <div className="text-muted">
                          <h5>{user.username}</h5>
                          <p className="mb-1">{user.email}</p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <h4 className="card-title mb-4">Change User Detail</h4>
            <Row>
                <Col md="6">
                  <Card>
                    <CardBody>
                      
                      <AvForm className="form-horizontal" onValidSubmit={(e, v) => handleValidSubmit(e, v)} >
                        <Row>
                          <Col md="6">
                            <div className="form-group mb-3">
                              <AvField
                                name="username"
                                label="User Name"
                                value={user.username}
                                className="form-control"
                                placeholder="Enter User Name"
                                disabled
                                type="text"
                                required
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group mb-3">
                              <AvField
                                name="email"
                                label="User Email"
                                value={user.email}
                                className="form-control"
                                placeholder="Enter User Email"
                                type="text"
                              />
                            </div>
                          </Col>
                          
                        </Row>
                        <Row>
                          <Col md="6">
                            <div className="form-group mb-3">
                              <AvField
                                name="first_name"
                                label="First Name"
                                value={user.first_name}
                                className="form-control"
                                placeholder="Enter First Name"
                                type="text"
                                required
                              />
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group mb-3">
                              <AvField
                                name="last_name"
                                label="Last Name"
                                value={user.last_name}
                                className="form-control"
                                placeholder="Enter Last Name"
                                type="text"
                                required
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                              <div className="mb-3">
                                  <AvField
                                      name="phone"
                                      label="Phone"
                                      placeholder="Contact Number(+91-9999999999)"
                                      type="text"
                                      errorMessage="Enter Only Digits"
                                      validate={{
                                          pattern: {
                                              value: "^[0-9+-]+$",
                                              errorMessage: "Invalid Contact"
                                          },
                                      }}
                                      />
                              </div>
                          </Col>
                          {user.role?
                          <Col md="6">
                            <div className="mb-3">
                                <Label>Type</Label>
                                <Select
                                value={userType}
                                options={UserType}
                                classNamePrefix="select2-selection"
                                isDisabled={true}
                                />
                            </div>
                        </Col>
                        :null}
                        </Row>
                        
                        <div className="text-center mt-4">
                          <Button type="submit" color="danger">
                            Update Detail
                          </Button>
                        </div>
                      </AvForm>
                      

                    </CardBody>
                  </Card>
                </Col>
            </Row>
          </>
          :
          ""}
      </div>
    </React.Fragment>
  )
}

export default UserProfile;